import { useContext } from "react";
import { AppContext } from "../../../contexts/AppContext";
import ItemPrinciples from "./ItemsPrinciples";

function ListItemsPrinciples() {
	const { translate } = useContext(AppContext);
	return (
		<>
			<ItemPrinciples
				numberItem={1}
				title={translate("global.careers.dna.list.0.title")}
				bodyText={translate("global.careers.dna.list.0.body")}
			/>
			<ItemPrinciples
				numberItem={2}
				title={translate("global.careers.dna.list.1.title")}
				bodyText={translate("global.careers.dna.list.1.body")}
			/>
			<ItemPrinciples
				numberItem={3}
				title={translate("global.careers.dna.list.2.title")}
				bodyText={translate("global.careers.dna.list.2.body")}
			/>
			<ItemPrinciples
				numberItem={4}
				title={translate("global.careers.dna.list.3.title")}
				bodyText={translate("global.careers.dna.list.3.body")}
			/>
			<ItemPrinciples
				numberItem={5}
				title={translate("global.careers.dna.list.4.title")}
				bodyText={translate("global.careers.dna.list.4.body")}
			/>
			<ItemPrinciples
				numberItem={6}
				title={translate("global.careers.dna.list.5.title")}
				bodyText={translate("global.careers.dna.list.5.body")}
			/>
			<ItemPrinciples
				numberItem={7}
				title={translate("global.careers.dna.list.6.title")}
				bodyText={translate("global.careers.dna.list.6.body")}
			/>
			<ItemPrinciples
				numberItem={8}
				title={translate("global.careers.dna.list.7.title")}
				bodyText={translate("global.careers.dna.list.7.body")}
			/>
		</>
	);
}

export default ListItemsPrinciples;
