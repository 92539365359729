import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ES from "./languages/es.json";
import PT from "./languages/pt.json";
import EN from "./languages/en.json";

i18n.use(initReactI18next).init({
	resources: {
		...ES,
		...PT,
		...EN,
	},
	debug: process.env.NODE_ENV !== "production",
	lng: "es",
	fallbackLng: "es",

	interpolation: {
		escapeValue: false,
	},
});
