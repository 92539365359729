import MultiCarousel from "react-multi-carousel";
import useMediaQuery from "../../../hooks/useMediaQuery";

import "react-multi-carousel/lib/styles.css";
import responsive from "../constantCarrusel";
import convenientLocations from "../../../assets/images/imagesAboutUs/convenientLocations.png";
import consumersNeeds from "../../../assets/images/imagesAboutUs/consumersNeeds.png";
import flexiblePayments from "../../../assets/images/imagesAboutUs/flexiblePayments.png";
import packages from "../../../assets/images/imagesAboutUs/packages.png";
import achievments from "../../../assets/images/imagesAboutUs/achievments.png";
import consumersPurchase from "../../../assets/images/imagesAboutUs/consumersPurchase.png";
import cornerStoresNumber from "../../../assets/images/imagesAboutUs/cornerStoresNumber.png";
import styles from "../AboutUs.module.css";
import Line1 from "../../../assets/images/imagesAboutUs/Line 1.png";

function BetterBusinessBanner() {
	const isDesktop = useMediaQuery("(min-width: 600px)");
	const featuresImg = [convenientLocations, consumersNeeds, flexiblePayments, packages];

	if (isDesktop) {
		return (
			<section className="bg-chiper-dark-blue">
				<div className="m-auto" style={{ maxWidth: 1170 }}>
					<img src={cornerStoresNumber} alt="" />
				</div>
			</section>
		);
	}

	return (
		<div className="bg-chiper-dark-blue py-20">
			<div className="mb-6   px-9 text-center ">
				<p className="font-bold text-3xl mb-5 text-white">
          Corner stores are the backbone of Latam’s everyday grocery supply
				</p>
				<img src={achievments} alt="" />
				<img src={consumersPurchase} alt="" />
			</div>
			<img className="block m-auto py-8" src={Line1} alt="" />
			<MultiCarousel
				swipeable
				draggable
				showDots
				responsive={responsive}
				transitionDuration={500}
				removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
				dotListClass={styles.dots}
			>
				{featuresImg.map((image, index) => (
					<div key={index.toString()}>
						<img src={image} alt="" />
					</div>
				))}
			</MultiCarousel>
		</div>
	);
}

export default BetterBusinessBanner;
