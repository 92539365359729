import styles from "../CareersInterviewTips.module.css";

interface ICardTips{
    tipIcon: any;
    tipTitle: string;
    tipBody: string;
}

function CardTips({ tipIcon, tipTitle, tipBody }: ICardTips) {
	return (
		<div className={styles.containerCardTips}>
			<div className={styles.customersItem}>
				<div className={styles.containerImgTips}>
					<img src={tipIcon} alt="tipIcon" />
				</div>
				<div className={styles.customersItemFlex}>
					<div className={styles.containerTextTips}>
						<p className={styles.textCareers}>
							<b>
								{tipTitle}
							</b>
						</p>
						<p className={styles.textCareers}>
							{tipBody}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CardTips;
