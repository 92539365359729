import { useContext } from "react";
import handleViewport from "react-in-viewport";
import StoresHelped from "./Helped";
import SEO from "../../components/SEO";
import { AppContext } from "../../contexts/AppContext";
import StoresBestPrices from "./BestPrices";
import StoresNeed from "./Need";
import StoresNextLevel from "./NextLevel";
import StoresBanner from "./Banner";
import StoresCustomers from "./Customers";
import StoresProducts from "./Products";
import StoresFirstOrder from "./FirstOrder";

function Stores() {
	const { translate } = useContext(AppContext);
	const ViewportBanner = handleViewport(StoresBanner);
	const ViewportProducts = handleViewport(StoresProducts);
	const ViewportBestPrices = handleViewport(StoresBestPrices);
	const ViewportStoresNeed = handleViewport(StoresNeed);
	const ViewportNextLevel = handleViewport(StoresNextLevel);
	const ViewportCustomers = handleViewport(StoresCustomers);
	const ViewportFirstOrder = handleViewport(StoresFirstOrder);

	return (
		<div data-testid="StoresScreen">
			<SEO
				title={translate("seo.stores.title")}
				description={translate("seo.stores.description")}
			/>
			<ViewportBanner />
			<ViewportProducts />
			<ViewportBestPrices />
			<ViewportStoresNeed />
			<ViewportNextLevel />
			<StoresHelped />
			<ViewportCustomers />
			<ViewportFirstOrder />
		</div>
	);
}

export default Stores;
