import { ChangeEvent } from "react";
import Trash from "../../../../assets/images/Trash.svg";
import FileArrowUp from "../../../../assets/images/FileArrowUp.svg";
import { dataFileDocument } from "../../../../contexts/FormContext";

interface CardFileMobileProps {
  sideTitle: string;
  onDelete: () => void;
  file?: dataFileDocument;
  refInputFile: any;
  handleInputFile: (e: ChangeEvent<any>) => void;
}

function CardFileMobile({
	sideTitle,
	onDelete,
	file,
	refInputFile,
	handleInputFile,
}: CardFileMobileProps) {
	return (
		<div className="border-2 border-slate-300 rounded w-full h-20 flex flex-row items-center mb-2">
			{file ? (
				<>
					<img src={file.preview} alt="file" className="w-28 h-20 p-2" />
					<div className="flex flex-col items-start">
						<p className="text-base font-semibold">
							<u>{sideTitle === "Front" ? "Parte delantera" : "Parte trasera"}</u>
						</p>
						<p className="text-xs">{file.size}kb</p>
					</div>
					<button type="button" onClick={onDelete} className="w-10 h-16 ml-auto pr-4">
						<img src={Trash} alt="TrashFile" />
					</button>
				</>
			) : (
				<>
					<div className="flex flex-col items-start">
						<p className="text-base font-semibold pl-4">
							{sideTitle === "Front" ? "Parte delantera" : "Parte trasera"}
						</p>
					</div>
					<label htmlFor="cameraFileInput" className="w-10 ml-auto pr-4">
						<img src={FileArrowUp} alt="TrashFile" />
						<input
							id="cameraFileInput"
							type="file"
							accept="image/*"
							capture="environment"
							ref={refInputFile}
							onChange={handleInputFile}
							className="hidden"
						/>
					</label>
				</>
			)}
		</div>
	);
}

export default CardFileMobile;
