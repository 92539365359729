import cn from "classnames";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { RoutesNames } from "../../constants";
import { AppContext } from "../../contexts/AppContext";
import styles from "./NotFound.module.css";

function NotFound() {
	const { translate, country } = useContext(AppContext);

	return (
		<section>
			<div className={cn("container", styles.notFound404Background)}>
				<div className={styles.notFound404Cols}>
					<h1 className={styles.notFound404}>404</h1>
				</div>
				<div className={styles.notFoundTextCols}>
					<h1 className={styles.notFoundTitle}>{translate("notFound.title")}</h1>
					<p>{translate("notFound.subtitle")}</p>
					<Link to={`/${country}/${RoutesNames.stores}`} className={styles.notFoundButton}>
						{translate("notFound.button.text")}
					</Link>
				</div>
			</div>
		</section>
	);
}

export default NotFound;
