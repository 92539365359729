import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppProvider from "./contexts/AppContext";
import App from "./App";
import "./i18n";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";

ReactDOM.render(
	<BrowserRouter>
		<ToastContainer autoClose={3800} closeOnClick hideProgressBar closeButton={false} />
		<AppProvider>
			<App />
		</AppProvider>
	</BrowserRouter>,
	document.getElementById("root"),
);
