/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { Button } from "chiper-sb-ecommerce";
import { useContext, useEffect, useState } from "react";
import { dataFileDocument, FormContext } from "../../../../contexts/FormContext";
import UploadDocument from "./uploadDocument";
import UploadDocumentMobile from "./uploadMobileDocument";
import styles from "./uploadFiles.module.css";

type ScreenUploadDocumentsObject = {
  onChange: (value?: dataFileDocument) => void;
  sideTitle: string;
  file?: dataFileDocument;
  isPdf?: boolean;
};

interface ScreenUploadDocumentsProps {
  items: ScreenUploadDocumentsObject[];
  onSuccess: () => void;
  onBack: (actual: boolean) => void;
}

function ScreenUploadDocuments({ items, onSuccess, onBack }: ScreenUploadDocumentsProps) {
	const { fileTypeUploadDoc } = useContext(FormContext);
	const [isComplete, setIsComplete] = useState(false);
	const [step, setStep] = useState<string>("Front");
	const [numbersDoc, setNumbersDoc] = useState<any>([]);
	const mobile = window.screen.width <= 480;

	const onChangeStatus = (value: string) => {
		setStep(value);
	};

	const subtitleByDocumentDesktop = () => {
		if (fileTypeUploadDoc === "IdDocument") {
			return "de tu documento de identificación";
		}
		if (fileTypeUploadDoc === "DriverLicense") {
			return "de tu licencia de conducción";
		}
		if (fileTypeUploadDoc === "SOAT") {
			return "del SOAT del vehículo";
		}
		if (fileTypeUploadDoc === "PropertyCard") {
			return "de la tarjeta de propiedad del vehículo";
		}
	};

	useEffect(() => {
		setIsComplete(true);
		const doc = items.filter((element) => element.file);
		setNumbersDoc(doc);
		items.forEach((item) => {
			if (!item.file?.id) {
				setIsComplete(false);
			}
		});
	}, [items]);

	return (
		<div>
			{!mobile ? (
				<>
					<p className="text-2xl pb-0 pl-8">
            Sube una foto donde la información <b>{subtitleByDocumentDesktop()}</b> sea
            claramente visible. Atención, solo se aceptarán archivos en formato JPG, PNG o PDF.
					</p>
					<div className={styles.buttonsUploadFiles}>
						{items.map((item) => (
							<UploadDocument
								key={item.sideTitle}
								onChange={item.onChange}
								sideTitle={item.sideTitle}
								uploadFile={item.file}
								isPdf={item.isPdf}
								onDelete={() => item.onChange()}
							/>
						))}
					</div>
					<div className={styles.buttonsUploadFiles}>
						<Button type="button" primary={false} onClick={() => onBack(isComplete)}>
              Volver
						</Button>
						<Button type="button" onClick={onSuccess} disabled={!isComplete} className={isComplete ? styles.btnGreen : ""}>
              Continuar
						</Button>
					</div>
				</>
			) : (
				<>
					{items.map((item) => {
						if (step === item.sideTitle) {
							return (
								<UploadDocumentMobile
									key={item.sideTitle}
									onChange={item.onChange}
									sideTitle={item.sideTitle}
									uploadFile={item.file}
									isPdf={item.isPdf}
									onDelete={() => item.onChange()}
									itemsMobile={items}
									changeStatus={onChangeStatus}
									showButtons={numbersDoc}
									isComplete={isComplete}
								/>
							);
						}
					})}
					{isComplete && (
						<Button type="button" onClick={onSuccess} className={styles.btnGreen}>
              Continuar
						</Button>
					)}
				</>
			)}
		</div>
	);
}

export default ScreenUploadDocuments;
