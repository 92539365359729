import { ChangeEvent, useContext } from "react";
import GlobeImg from "../../../assets/images/globe.svg";
import { AppContext } from "../../../contexts/AppContext";
import styles from "./SelectCountry.module.css";

function FooterSelectLanguage() {
	const { language, changeLanguage, languages } = useContext(AppContext);

	const handleLanguageChange = (event: ChangeEvent<HTMLSelectElement>) => {
		changeLanguage(event.target.value);
	};

	return (
		<div hidden className={styles.selectContainer}>
			<img src={GlobeImg} alt="Globe" width={25} />
			<select
				name="language"
				id="language"
				className={styles.select}
				onChange={handleLanguageChange}
				value={language}
			>
				<option value={languages.pt}>Português</option>
				<option value={languages.es}>Español</option>
			</select>
		</div>
	);
}

export default FooterSelectLanguage;
