const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 600 },
		items: 3,
		slidesToSlide: 1,
		partialVisibilityGutter: 30,
	},

	mobile: {
		breakpoint: { max: 599, min: 0 },
		items: 1,
		slidesToSlide: 1,
		partialVisibilityGutter: 30,
	},
};

export default responsive;
