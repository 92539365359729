import { useContext } from "react";
import { AppContext } from "../../../contexts/AppContext";
import styles from "./ChiperPeople.module.css";
import { ViewportProps } from "../../Stores/viewport.interface";
import CareersChiperPeople from "../../../components/Carousel/CareersChiperPeople";
import thirdDots from "../../../assets/images/imagesCareers/thirdDots.png";

function ChiperPeople({ forwardedRef }: ViewportProps) {
	const { translate } = useContext(AppContext);

	return (
		<section id="sectionChiperPeople" className={styles.sectionChiperPeople} ref={forwardedRef}>
			<div>
				<div className={styles.headerChiperPeople}>
					<h1>{translate("global.careers.testimonial.title")}</h1>
					<img src={thirdDots} alt="imgPoints" />
				</div>
				<div>
					<div className={styles.containerCarouselChiperPeople}>
						<CareersChiperPeople />
					</div>
				</div>
			</div>
		</section>
	);
}

export default ChiperPeople;
