import { Button } from "chiper-sb-ecommerce";
import useMediaQuery from "../../../hooks/useMediaQuery";
import manWithNotebook from "../../../assets/images/imagesAboutUs/manWithNotebook.png";
import secondDots from "../../../assets/images/imagesCareers/secondDots.png";

function MissionChiperBanner() {
	const isDesktop = useMediaQuery("(min-width: 600px)");
	return (
		<div className={isDesktop ? "p-20 mt-20" : "px-5 py-20"}>
			<p className={`font-bold text-8xl text-center  ${isDesktop ? "text-8xl" : "text-6xl"}`}>
        At Chiper, we are mission focused and customer obssesed
			</p>
			<div className={isDesktop ? "flex justify-center" : "flex-col justify-center"}>
				<div>
					<img src={manWithNotebook} alt="" />
				</div>
				<div style={{ maxWidth: 667, textAlign: "right" }}>
					{isDesktop && <img className="text-center mt-28" src={secondDots} alt="" />}
					<p style={{ textShadow: "#cbcbcb 0px 4px 3px" }} className="mt-10 ">
            Our team works relentlessly to grow a healthy and scalable company that will push
            society forward and we are always looking for the hungriest and most driven individuals
            out there to join us on this journey.
					</p>
					<Button
						onClick={() => {
							window.open("https://chiper.bamboohr.com/jobs/");
						}}
						className="mt-10"
						style={{ maxWidth: 266 }}
					>
            Explore 100+ Roles
					</Button>
				</div>
			</div>
		</div>
	);
}

export default MissionChiperBanner;
