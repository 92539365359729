/* eslint-disable global-require */
import { ChangeEvent, useContext, useEffect } from "react";
import { Checkbox, InputField } from "chiper-sb-ecommerce";
import Axios from "axios";
import { AppContext } from "../../../../contexts/AppContext";
import { ViewportProps } from "../../../Stores/viewport.interface";
import styles from "../RegisterDrivers.module.css";
import { FormContext } from "../../../../contexts/FormContext";

function RegisterDriversCO({ forwardedRef }: ViewportProps) {
	const { REACT_APP_LANDING_API } = process.env;
	const { changeRegisterScreen } = useContext(AppContext);
	const {
		name,
		changeNameRegisterDriverForm,
		lastName,
		changeLastNameRegisterDriverForm,
		cellPhone,
		changeCellphoneRegisterDriverForm,
		city,
		changeCityRegisterDriverForm,
		driverNoVehicle,
		changeDriverNoVehicle,
		driverWithVehicle,
		changeDriverWithVehicle,
		acceptTyC,
		changeAcceptTyC,
		leadTypeId,
		changeLeadTypeIdRegisterDriverForm,
		leadId,
		changeLeadIdRegisterDriverForm,
		setRegistrationFullComplete,
		setScreenStartUploadDocuments,
		setFileSOATUpload,
		setFileDocUploadFront,
		setFileDocUploadBack,
		setFileLicUploadFront,
		setFileLicUploadBack,
		setFilePropertyUploadBack,
		setFilePropertyUploadFront,
	} = useContext(FormContext);

	const handleOnClick = () => {
		changeRegisterScreen(true);
		setRegistrationFullComplete(false);
		setScreenStartUploadDocuments(false);
		setFileDocUploadFront(undefined);
		setFileDocUploadBack(undefined);
		setFileLicUploadFront(undefined);
		setFileLicUploadBack(undefined);
		setFilePropertyUploadFront(undefined);
		setFilePropertyUploadBack(undefined);
		setFileSOATUpload(undefined);
	};

	const getLeadTypeId = async () => {
		Axios.get(`${REACT_APP_LANDING_API}lead-type`)
			.then((resp: any) => {
				if (leadTypeId === "0") {
					changeLeadIdRegisterDriverForm(resp?.data[0]?.id);
					return;
				}
				if (leadTypeId === "1") {
					changeLeadIdRegisterDriverForm(resp?.data[1]?.id);
				}
			});
	};

	useEffect(() => {
		getLeadTypeId();
	}, [leadTypeId, driverNoVehicle, driverWithVehicle, leadId]);

	return (
		<section id="register" className={styles.registerSection} ref={forwardedRef} data-testid="firstFormDrivers">
			<div className={styles.registerContainer}>
				<div className={styles.registerText}>
					<h1>
						<b>Regístrate</b>
					</h1>
					<h1>
						<b>con Chiper</b>
					</h1>
					<h1>
						<b>Repartidores</b>
					</h1>
					<h1>
						<b> y comienza </b>
					</h1>
					<h1>
						<b>a generar</b>
					</h1>
					<h1>
						<b className={styles.underline}>más ingresos.</b>
					</h1>
				</div>
				<div className={styles.containerRegisterForm}>
					<form className={styles.registerForm}>
						<div className={styles.divRegisterForm}>
							<div className={styles.divDriverTitle}>
								<p className={styles.driverTitle}>¡Regístrate ahora!</p>
							</div>
							<div className={styles.firstDivInputs}>
								<InputField
									type="text"
									placeholder="Nombre"
									value={name}
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										changeNameRegisterDriverForm(e.target.value);
									}}
									className={styles.firstInputs}
								/>
								<InputField
									type="text"
									placeholder="Apellido"
									value={lastName}
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										changeLastNameRegisterDriverForm(e.target.value);
									}}
									className={styles.firstInputs}
								/>
							</div>
							<div className={styles.secondDivInputs}>
								<InputField
									type="number"
									placeholder="Celular"
									mask="999 999-9999"
									value={cellPhone}
									onInput={(event: ChangeEvent<HTMLInputElement>) => {
										const phoneNumber = event.target.value.replace(/-|\(|\)| |_/g, "");
										if (phoneNumber.length <= 10) {
											changeCellphoneRegisterDriverForm(phoneNumber);
										}
									}}
								/>
							</div>
							<div className={styles.secondDivInputs}>
								<InputField
									type="text"
									placeholder="Ciudad"
									value={city}
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										changeCityRegisterDriverForm(e.target.value);
									}}
								/>
							</div>
							<div className={styles.thirdDivInputs}>
								<p className={styles.bodyFirstTextDriver}>
                  ¿Tienes un vehículo o necesitas financiar uno?
								</p>
								<div className={styles.containerCheckboxRound}>
									<div className={styles.checkboxRoundDiv}>
										<Checkbox
											name="radioVehicle"
											type="radio"
											checked={driverWithVehicle}
											onChange={() => {
												changeDriverWithVehicle(true);
												changeDriverNoVehicle(false);
												changeLeadTypeIdRegisterDriverForm("0");
											}}
										/>
										<p className={styles.bodyTextDriver}>Tengo vehículo</p>
									</div>
									<div className={styles.checkboxRoundDiv}>
										<Checkbox
											name="radioNoVehicle"
											type="radio"
											checked={driverNoVehicle}
											onChange={() => {
												changeDriverNoVehicle(true);
												changeDriverWithVehicle(false);
												changeLeadTypeIdRegisterDriverForm("1");
											}}
										/>
										<p className={styles.bodyTextDriver}>Necesito financiar</p>
									</div>
								</div>
								<div className={styles.checkboxRoundDiv}>
									<Checkbox
										name="checkboxTyC"
										type="checkbox"
										checked={acceptTyC}
										onChange={() => changeAcceptTyC(!acceptTyC)}
									/>
									<p className={styles.bodyTextDriver}>
                    Autorizo los{" "}
										<b>
											<u>
												<a
													href={require("../../../../assets/TermsDriversCO/TyCDriversCO.pdf")}
													target="_blank"
													rel="noreferrer"
												>
                          términos y condiciones
												</a>
											</u>
										</b>{" "}
                    del uso de la plataforma y la{" "}
										<b>
											<u>
												<a
													href={require("../../../../assets/TermsDriversCO/DatosPersonalesDriversCO.pdf")}
													target="_blank"
													rel="noreferrer"
												>
                          política de tratamiento de datos personales
												</a>
											</u>
										</b>{" "}
                    .
									</p>
								</div>
								<button type="button" className={styles.registerBtn} onClick={handleOnClick}>
                  Registrarme
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</section>
	);
}

export default RegisterDriversCO;
