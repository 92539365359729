import { useContext } from "react";
import FooterSocialLinks from "../FooterSocialLinks";
import FooterSelectLanguage from "../FooterSelectLanguage";
import { AppContext } from "../../../contexts/AppContext";
import styles from "../Footer.module.css";
import FooterLegalLinks from "../FooterLegalLinks";
import { RoutesNames } from "../../../constants";

function FooterCL() {
	const { countryCodes, translate } = useContext(AppContext);
	const year = new Date().getFullYear();

	const facebook = {
		url: "https://www.facebook.com/chiper.mexico",
		utm_source: "",
		utm_medium: "",
		utm_term: "",
		utm_content: "",
		utm_campaign: "",
	};

	const youtube = {
		url: "https://www.youtube.com/channel/UCwMEG-pBJ8atOTcM46yc7GA/videos",
		utm_source: "",
		utm_medium: "",
		utm_term: "",
		utm_content: "",
		utm_campaign: "",
	};

	const legalLinks = [
		{
			to: `/${countryCodes.CL}/${RoutesNames.terms}/${RoutesNames.conditions}`,
			name: translate("terms.document.conditions"),
			className: styles.legalLink,
		},
		{
			to: `/${countryCodes.CL}/${RoutesNames.terms}/${RoutesNames.privacy}`,
			name: translate("terms.document.privacy"),
			className: styles.legalLink,
		},
	];

	return (
		<div className={styles.footer}>
			<div className={styles.containerFooter}>
				<div className={styles.footerSocialLinksCols}>
					<div className={styles.footerSocialLinks}>
						<FooterSocialLinks facebook={facebook} youtube={youtube} />
						<FooterSelectLanguage />
					</div>
				</div>

				<div className={styles.footerLegalLinksCols}>
					<div className={styles.footerLegalLinks}>
						<FooterLegalLinks links={legalLinks} />
					</div>
				</div>
			</div>

			<div className={styles.footerChiperCols}>
				<p className={styles.chiperText}>
          &copy; {year} - COMERCIALIZADORA Y ABASTECEDORA DIGITAL SPA.
				</p>
				<p className={styles.chiperText}>{translate("seo.footer.slogan")}</p>
			</div>
		</div>
	);
}

export default FooterCL;
