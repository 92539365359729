import { useContext } from "react";
import cn from "classnames";
import { AppContext } from "../../../contexts/AppContext";
import styles from "./NextLevel.module.css";
import imagePT from "../../../assets/images/pt/pt-image-5.png";
import imageES from "../../../assets/images/es/es-image-5.png";
import { ViewportProps } from "../viewport.interface";

function StoresNextLevel({ forwardedRef, inViewport }: ViewportProps) {
	const { country, countryCodes, translate } = useContext(AppContext);
	const msgSubTitle = country === countryCodes.CL ? "nextLevel.subtitle.cl" : "nextLevel.subtitle";

	return (
		<section id="next-level" ref={forwardedRef}>
			<div className={styles.nextLevelBackground}>
				<div className="container">
					<div className={styles.nextLevelTitleCols}>
						<div>
							<h1
								className={
									inViewport
										? cn(styles.animationStart, styles.animationEnd)
										: styles.animationStart
								}
							>
								{translate("nextLevel.title")}
							</h1>
						</div>
					</div>
					<div className={styles.nextLevelImageTextCols}>
						<div className={styles.nextLevelImage}>
							{country === countryCodes.BR ? <img src={imagePT} alt="" /> : <img src={imageES} alt="" />}
						</div>
						<div className={styles.nextLevelText}>
							<p>{translate(msgSubTitle)}</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default StoresNextLevel;
