import React, { useEffect } from "react";

function withRedirect(WrappedComponent: React.FC<any>) {
	function WithRedirect(props: any) {
		useEffect(() => {
			if (window.innerWidth <= 1024 && !window.location.href.includes("/terms")) {
				const country = window.location.href.includes(".mx") ? "mx" : "co";
				window.location.href = `https://chiper.${country}`;
			}
		}, []);
		return <WrappedComponent {...props} />;
	}
	return WithRedirect;
}
export default withRedirect;
