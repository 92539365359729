import { useContext } from "react";
import { Button } from "chiper-sb-ecommerce";
import { ViewportProps } from "../../Stores/viewport.interface";
import styles from "./StepsToApply.module.css";
import imgSteps from "../../../assets/images/imagesCareers/imgSteps.png";
import mobileImgSteps from "../../../assets/images/imagesCareers/mobileImgSteps.png";
import LinkedinLogo from "../../../assets/images/imagesCareers/LinkedinLogo.svg";
import { AppContext } from "../../../contexts/AppContext";

function StepsToApply({ forwardedRef }: ViewportProps) {
	const { translate } = useContext(AppContext);
	const mobile = window.screen.width <= 480;

	return (
		<section className={styles.sectionStepsToApply} id="stepsToApply" ref={forwardedRef}>
			<div className={styles.containerStepsToApply}>
				<div className={styles.titleStepsToApply}>
					<h1>{translate("global.careers.apply.title")}</h1>
				</div>
				<div className={styles.bodyStepsToApply}>
					<div className={styles.imgStepsToApply}>
						<img src={mobile ? mobileImgSteps : imgSteps} alt="imgPurpose" />
					</div>
					<div className={styles.stepByStepsToApply}>
						<li className={styles.stepApply1}>1</li>
						<p>
							{translate("global.careers.apply.list.0.body")}
						</p>
						<a href="https://www.linkedin.com/company/chiperinc/mycompany/" target="_blank" rel="noreferrer">
							<p className="flex">
								<img src={LinkedinLogo} alt="linkedIn" />
								<b>LinkedIn/chiperinc</b>
							</p>
						</a>
						<li className={styles.stepApply}>2</li>
						<p>
							{translate("global.careers.apply.list.1.body")}
						</p>
						<Button onClick={() => { window.open("https://chiper.bamboohr.com/jobs/"); }} className={styles.buttonApply}>
							{translate("global.careers.button.applyNow")}
						</Button>
						<li className={styles.stepApply}>3</li>
						<p>
							{translate("global.careers.apply.list.2.body")}
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default StepsToApply;
