import { useContext } from "react";
import cn from "classnames";
import { AppContext } from "../../../contexts/AppContext";

import styles from "./Customers.module.css";
import { ViewportProps } from "../viewport.interface";
import CarouselCustomers from "../../../components/Carousel";

function StoresCustomers({ inViewport, forwardedRef }: ViewportProps) {
	const { translate, country } = useContext(AppContext);
	const msgTitle = country === "cl" ? "customers.title.cl" : "customers.title";

	return (
		<section id="customers" ref={forwardedRef}>
			<div className={styles.customersBackground}>
				<div className="container">
					<div className={styles.customersTitleCols}>
						<div className="overflow-hidden">
							<h1
								className={
									inViewport
										? cn(styles.animationStart, styles.animationEnd)
										: styles.animationStart
								}
							>
								{translate(msgTitle)}
							</h1>
						</div>
					</div>
					<div className={styles.customersCarouselCols}>
						<CarouselCustomers />
					</div>
				</div>
			</div>
		</section>
	);
}

export default StoresCustomers;
