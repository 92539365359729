import { createContext, ReactNode, useContext, useState } from "react";

interface FormProviderProps{
    children: ReactNode
}

export type dataFileDocument = {
  name?: string,
  size?: number,
  preview?: string,
  id?: string,
  progress?: number,
}

export interface FormContextProps{
    name: string;
    changeNameRegisterDriverForm: (value:string) => void;
    lastName: string;
    changeLastNameRegisterDriverForm: (value:string) => void;
    cellPhone: string;
    changeCellphoneRegisterDriverForm: (value:string) => void;
    city: string;
    changeCityRegisterDriverForm: (value:string) => void;
    progress: number;
    changeUploadFileProgressBar: (value: number) => void;
    driverWithVehicle: boolean;
    driverNoVehicle: boolean;
    changeDriverNoVehicle: (value: boolean) => void;
    changeDriverWithVehicle: (value: boolean) => void;
    acceptTyC: boolean;
    changeAcceptTyC: (value: boolean) => void;
    comeBackFirstStep: boolean;
    setComeBackFirstStep: (value: boolean) => void;
    fileIdUploadDoc: string;
    setFileIdUploadDoc: (value: string) => void;
    fileTypeUploadDoc: string;
    setFileTypeUploadDoc: (value: string) => void;
    leadIdUploadDoc: string;
    setLeadIdUploadDoc: (value: string) => void;
    sideUploadDoc: string;
    setSideUploadDoc: (value: string) => void;
    leadTypeId: string;
    changeLeadTypeIdRegisterDriverForm: (value: string) => void;
    leadId: string;
    changeLeadIdRegisterDriverForm: (value: string) => void;
    fileDocUploadFront?: dataFileDocument;
    setFileDocUploadFront: (value?: dataFileDocument) => void;
    fileDocUploadBack?: dataFileDocument;
    setFileDocUploadBack: (value?: dataFileDocument) => void;
    fileLicUploadFront?: dataFileDocument;
    setFileLicUploadFront: (value?: dataFileDocument) => void;
    fileLicUploadBack?: dataFileDocument;
    setFileLicUploadBack: (value?: dataFileDocument) => void;
    filePropertyUploadFront?: dataFileDocument;
    setFilePropertyUploadFront: (value?: dataFileDocument) => void;
    filePropertyUploadBack?: dataFileDocument;
    setFilePropertyUploadBack: (value?: dataFileDocument) => void;
    fileSOATUpload?: dataFileDocument;
    setFileSOATUpload: (value?: dataFileDocument) => void;
    registrationFullComplete: boolean;
    setRegistrationFullComplete: (value: boolean) => void;
    screenStartUploadDocuments: boolean;
    setScreenStartUploadDocuments: (value: boolean) => void;
    renderImageStep2: boolean;
    setRenderImageStep2: (value: boolean) => void;
}

export const FormContext = createContext({} as FormContextProps);

function FormProvider({ children }: FormProviderProps) {
	// Form Register Drivers
	const [name, setName] = useState("");
	const [lastName, setLastName] = useState("");
	const [cellPhone, setCellPhone] = useState("");
	const [city, setCity] = useState("");
	const [driverWithVehicle, setDriverWithVehicle] = useState(false);
	const [driverNoVehicle, setDriverNoVehicle] = useState(false);
	const [acceptTyC, setAcceptTyC] = useState(false);
	const [leadTypeId, setLeadTypeId] = useState("");
	const [leadId, setLeadId] = useState("");
	const [comeBackFirstStep, setComeBackFirstStep] = useState(false);

	// Document upload
	const [fileTypeUploadDoc, setFileTypeUploadDoc] = useState("");
	const [fileIdUploadDoc, setFileIdUploadDoc] = useState("");
	const [leadIdUploadDoc, setLeadIdUploadDoc] = useState("");
	const [sideUploadDoc, setSideUploadDoc] = useState("");
	const [fileDocUploadFront, setFileDocUploadFront] = useState<dataFileDocument>();
	const [fileDocUploadBack, setFileDocUploadBack] = useState<dataFileDocument>();
	const [fileLicUploadFront, setFileLicUploadFront] = useState<dataFileDocument>();
	const [fileLicUploadBack, setFileLicUploadBack] = useState<dataFileDocument>();
	const [filePropertyUploadFront, setFilePropertyUploadFront] = useState<dataFileDocument>();
	const [filePropertyUploadBack, setFilePropertyUploadBack] = useState<dataFileDocument>();
	const [fileSOATUpload, setFileSOATUpload] = useState<dataFileDocument>();
	const [registrationFullComplete, setRegistrationFullComplete] = useState(false);
	const [screenStartUploadDocuments, setScreenStartUploadDocuments] = useState(false);
	const [renderImageStep2, setRenderImageStep2] = useState(false);

	// ProgressBar
	const [progress, setProgress] = useState(0);

	const changeNameRegisterDriverForm = (value: string) => {
		setName(value);
	};

	const changeLastNameRegisterDriverForm = (value: string) => {
		setLastName(value);
	};

	const changeCellphoneRegisterDriverForm = (value: string) => {
		setCellPhone(value);
	};
	const changeCityRegisterDriverForm = (value: string) => {
		setCity(value);
	};
	const changeUploadFileProgressBar = (value: number) => {
		setProgress(value);
	};
	const changeDriverWithVehicle = (value: boolean) => {
		setDriverWithVehicle(value);
	};
	const changeDriverNoVehicle = (value: boolean) => {
		setDriverNoVehicle(value);
	};
	const changeAcceptTyC = (value: boolean) => {
		setAcceptTyC(value);
	};
	const changeLeadTypeIdRegisterDriverForm = (value: string) => {
		setLeadTypeId(value);
	};

	const changeLeadIdRegisterDriverForm = (value: string) => {
		setLeadId(value);
	};
	return (
		<FormContext.Provider
			value={{
				name,
				changeNameRegisterDriverForm,
				lastName,
				changeLastNameRegisterDriverForm,
				cellPhone,
				changeCellphoneRegisterDriverForm,
				city,
				changeCityRegisterDriverForm,
				progress,
				changeUploadFileProgressBar,
				driverNoVehicle,
				changeDriverNoVehicle,
				driverWithVehicle,
				changeDriverWithVehicle,
				acceptTyC,
				changeAcceptTyC,
				comeBackFirstStep,
				setComeBackFirstStep,
				fileIdUploadDoc,
				setFileIdUploadDoc,
				leadIdUploadDoc,
				setLeadIdUploadDoc,
				sideUploadDoc,
				setSideUploadDoc,
				fileTypeUploadDoc,
				setFileTypeUploadDoc,
				leadTypeId,
				changeLeadTypeIdRegisterDriverForm,
				leadId,
				changeLeadIdRegisterDriverForm,
				fileDocUploadFront,
				setFileDocUploadFront,
				fileDocUploadBack,
				setFileDocUploadBack,
				fileLicUploadFront,
				setFileLicUploadFront,
				fileLicUploadBack,
				setFileLicUploadBack,
				filePropertyUploadFront,
				setFilePropertyUploadFront,
				filePropertyUploadBack,
				setFilePropertyUploadBack,
				fileSOATUpload,
				setFileSOATUpload,
				registrationFullComplete,
				setRegistrationFullComplete,
				screenStartUploadDocuments,
				setScreenStartUploadDocuments,
				renderImageStep2,
				setRenderImageStep2,
			}}
		>
			{children}
		</FormContext.Provider>
	);
}

export const useFormContext = () => useContext(FormContext);

export default FormProvider;
