/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	ChangeEvent, useContext, useEffect, useRef, useState,
} from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import { dataFileDocument, FormContext } from "../../../../contexts/FormContext";
import backDocMobile from "../../../../assets/images/backDocMobile.png";
import frontDocMobile from "../../../../assets/images/frontDocMobile.png";
import backOkMobile from "../../../../assets/images/backOkMobile.png";
import frontOkMobile from "../../../../assets/images/frontOkMobile.png";
import camera from "../../../../assets/images/camera.svg";
import styles from "./uploadDocumentMobile.module.css";
import ToastMessageStyle from "../../../../components/ToastAlert";
import CircularStatic from "../../../../components/ProgressBar/CircularProgress";
import CardFileMobile from "./cardFileMobile";

type ScreenUploadDocumentsObject = {
  onChange: (value?: dataFileDocument) => void;
  sideTitle: string;
  file?: dataFileDocument;
  isPdf?: boolean;
};
interface UploadDocumentMobileProps {
  sideTitle: string;
  onChange: (id: dataFileDocument) => void;
  uploadFile?: dataFileDocument;
  isPdf?: boolean;
  onDelete: () => void;
  itemsMobile: ScreenUploadDocumentsObject[];
  changeStatus: any;
  showButtons: any;
  isComplete: boolean;
}

function UploadDocumentMobile({
	sideTitle,
	onChange,
	uploadFile,
	isPdf,
	onDelete,
	itemsMobile,
	changeStatus,
	showButtons,
	isComplete,
}: UploadDocumentMobileProps) {
	const { fileIdUploadDoc, leadIdUploadDoc, fileTypeUploadDoc } = useContext(FormContext);
	const { REACT_APP_LANDING_API } = process.env;
	const [imageFile, setImageFile] = useState<any>();
	const [dataUploadFile, setDataUploadFile] = useState<boolean | any>();
	const [dataImage, setDataImage] = useState<any>();
	const [stepUpload, setStepUpload] = useState<number>(0);
	const [onSendFile, setOnSendFile] = useState<boolean>(false);
	const image = useRef<HTMLImageElement>(null);
	const inputFile = useRef<HTMLInputElement>(null);
	const [urlImage, setUrlImage] = useState<string>();

	const handleInputFile = (e: ChangeEvent<any>) => {
		image?.current?.setAttribute("src", window.URL.createObjectURL(e.target.files[0]));
		setUrlImage(window.URL.createObjectURL(e.target.files[0]));
		setImageFile(e.target.files[0]);
	};

	const updateFilesMobile = () => {
		setStepUpload(2);
		setOnSendFile(true);
		const formData = new FormData();
		formData.append("file", imageFile);
		formData.append("fileTypeId", fileIdUploadDoc);
		formData.append("leadId", leadIdUploadDoc);
		formData.append("side", sideTitle);
		setDataImage(imageFile);
		Axios({
			method: "POST",
			data: formData,
			url: `${REACT_APP_LANDING_API}lead-file`,
			headers: {
				"Content-Type": "multipart/form-data",
			},
			onUploadProgress(e: any) {
				if (e.lengthComputable) {
					const percentage = Math.round((e.loaded / e.total) * 100);
					onChange({ progress: percentage });
				}
			},
		})
			.then((resp: any) => {
				setDataUploadFile(resp.data);
				onChange({
					name: imageFile.name,
					size: imageFile.size,
					id: resp.data.id as string,
					preview: urlImage,
					progress: 0,
				});
				setOnSendFile(false);
				changeStatus(!isPdf ? "Back" : "Front");
			})
			.catch((e: any) => {
				toast.error(<ToastMessageStyle message={e?.response?.data?.message} />, {
					position: toast.POSITION.TOP_CENTER,
				});
				setStepUpload(1);
			});
	};

	// eslint-disable-next-line consistent-return
	const textBodyByDocument = () => {
		if (fileTypeUploadDoc === "IdDocument") {
			return "de tu documento de identificación";
		}
		if (fileTypeUploadDoc === "DriverLicense") {
			return "de tu licencia de conducción";
		}
		if (fileTypeUploadDoc === "SOAT") {
			return "del SOAT del vehículo";
		}
		if (fileTypeUploadDoc === "PropertyCard") {
			return "de la tarjeta de propiedad del vehículo";
		}
	};

	useEffect(() => {
		if (imageFile) {
			updateFilesMobile();
		}
	}, [imageFile]);

	useEffect(() => {
		if (!onSendFile && isComplete && stepUpload === 2) {
			setTimeout(() => {
				setStepUpload(3);
			}, 3000);
		}
		if (isComplete && stepUpload !== 2) {
			setStepUpload(3);
		}
	});

	useEffect(() => {
		if (!isComplete) {
			setStepUpload(1);
		}
	}, []);

	useEffect(() => {
		if (showButtons.length === 0 && stepUpload === 3) {
			setStepUpload(1);
		}

		if (stepUpload === 3 && showButtons.length === 1 && showButtons[0].sideTitle === "Back") {
			changeStatus("Front");
		}
	});

	return (
		<div>
			{/* Seleccionamos el archivo */}
			{stepUpload === 1 && (
				<>
					<div className={styles.containerBodyText}>
						<img
							src={sideTitle === "Front" ? frontDocMobile : backDocMobile}
							alt="imgDocument"
							className="mt-5 h-20 w-24"
						/>
						<p className={styles.titleSideCamera}>
							<b>Tomar foto {sideTitle === "Front" ? "delantera" : "trasera"}</b>
						</p>
						<p className="text-base font-medium">
              o sube una foto donde la información {textBodyByDocument()} sea
              claramente visible. <br />
              Atención, solo se aceptarán archivos en formato JPG, PNG o PDF.
						</p>
					</div>
					<div id="preview" className="flex flex-col">
						<label htmlFor="cameraFileInput" className={styles.buttonGreen}>
							<span className={styles.containerInputBtnGreen}>
								<img src={camera} alt="camera" />
								<p className="text-base mx-auto">
                  Tomar foto {sideTitle === "Front" ? "delantera" : "trasera"}
								</p>
							</span>
							<input
								id="cameraFileInput"
								type="file"
								accept="image/*"
								capture="environment"
								ref={inputFile}
								onChange={handleInputFile}
								className="hidden"
							/>
						</label>
						<label htmlFor="galleryFileInput" className={styles.buttonOpenDoc}>
							<span className={styles.containerInputBtnWhite}>
								<p className="text-base">Busca tus archivos</p>
							</span>
							<input
								id="galleryFileInput"
								name="galleryFileInput"
								type="file"
								accept="image/*"
								ref={inputFile}
								onChange={handleInputFile}
								className="hidden"
							/>
						</label>
					</div>
				</>
			)}

			{/* Enviamos el archivo */}

			{stepUpload === 2 && (
				<div className={styles.containerBodyText}>
					{onSendFile ? (
						<>
							<img
								src={sideTitle === "Front" ? frontDocMobile : backDocMobile}
								alt="imgDocument"
								className="mt-5 h-20 w-24 mb-8"
							/>
							<p className="text-base font-medium mb-16">Tu imagen se está cargando</p>
							{!!uploadFile?.progress && <CircularStatic value={uploadFile?.progress} />}
						</>
					) : (
						<>
							<img
								src={sideTitle === "Front" ? frontOkMobile : backOkMobile}
								alt="imgDocument"
								className="mt-5 h-20 w-24 mb-8"
							/>
							<p className="text-lg font-bold mb-16">Foto enviada con éxito!</p>
						</>
					)}
				</div>
			)}

			{/* Confirmamos ambos archivos */}
			{stepUpload === 3 && (
				<div className={styles.containerBodyText}>
					<p className="text-xl font-medium mt-10 mb-20">
						{" "}
            Asegúrate de que todos los datos sean legibles y que la foto sea clara
					</p>
					{itemsMobile.map((itemMobile) => (
						<CardFileMobile
							key={itemMobile.sideTitle}
							sideTitle={itemMobile.sideTitle}
							onDelete={() => itemMobile.onChange()}
							file={itemMobile.file}
							refInputFile={inputFile}
							handleInputFile={handleInputFile}
						/>
					))}
					{!isComplete && !isPdf && sideTitle === "Front" && (
						<div id="preview" className="flex flex-col w-full mt-28">
							<label htmlFor="cameraFileInput" className={styles.buttonGreen}>
								<span className={styles.containerInputBtnGreen}>
									<img src={camera} alt="camera" />
									<p className="text-base mx-auto">
                    Tomar foto {sideTitle === "Front" ? "delantera" : "trasera"}
									</p>
								</span>
								<input
									id="cameraFileInput"
									type="file"
									accept="image/*"
									capture="environment"
									ref={inputFile}
									onChange={handleInputFile}
									className="hidden"
								/>
							</label>
							<label htmlFor="galleryFileInput" className={styles.buttonOpenDoc}>
								<span className={styles.containerInputBtnWhite}>
									<p className="text-base">Busca tus archivos</p>
								</span>
								<input
									id="galleryFileInput"
									name="galleryFileInput"
									type="file"
									accept="image/*"
									ref={inputFile}
									onChange={handleInputFile}
									className="hidden"
								/>
							</label>
						</div>
					)}
					{!isComplete && (
						<div id="preview" className="flex flex-col w-full mt-28">
							<label htmlFor="cameraFileInput" className={styles.buttonGreen}>
								<span className={styles.containerInputBtnGreen}>
									<img src={camera} alt="camera" />
									<p className="text-base mx-auto">
                    Tomar foto {sideTitle === "Front" ? "delantera" : "trasera"}
									</p>
								</span>
								<input
									id="cameraFileInput"
									type="file"
									accept="image/*"
									capture="environment"
									ref={inputFile}
									onChange={handleInputFile}
									className="hidden"
								/>
							</label>
							<label htmlFor="galleryFileInput" className={styles.buttonOpenDoc}>
								<span className={styles.containerInputBtnWhite}>
									<p className="text-base">Busca tus archivos</p>
								</span>
								<input
									id="galleryFileInput"
									name="galleryFileInput"
									type="file"
									accept="image/*"
									ref={inputFile}
									onChange={handleInputFile}
									className="hidden"
								/>
							</label>
						</div>
					)}
				</div>
			)}
		</div>
	);
}

export default UploadDocumentMobile;
