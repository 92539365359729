import { ViewportProps } from "../../../Stores/viewport.interface";
import styles from "../BannerDriver.module.css";
import bannerDrivers1 from "../../../../assets/images/es/bannerDrivers1.png";

function DriverBannerCO({ forwardedRef }: ViewportProps) {
	const mobile = window.screen.width <= 480;

	return (
		<section id="banner" className={styles.banner} ref={forwardedRef} data-testid="bannerDriver">
			<div className={styles.containerBanner}>
				<div className={styles.bannerDriverTitle}>
					<h1 className={styles.firstTitle}>
						<b>Prepárate para</b>
					</h1>
					<h1>
						<b className={styles.underline}>ganar&nbsp;</b>
						<b className={styles.underline}>dinero</b>
					</h1>
					<h1>
						<b>con Chiper</b>
					</h1>
					<div className={styles.bannerDriverSubtitle}>
						<p className={styles.bannerDriverTextSubtitle}>
              Gana dinero con cada entrega realizada. <br />
              Recibe pedidos a través de la aplicación y gana bonos por los objetivos alcanzados.
						</p>
					</div>
				</div>
				<div className={!mobile ? "w-7/12" : ""}>
					<img className={styles.imgDriver1} src={bannerDrivers1} alt="bannerDriver" />
				</div>
			</div>
		</section>
	);
}

export default DriverBannerCO;
