import styles from "./ChiperOffers.module.css";

interface IItemsOffers{
    iconItem: any;
    text: string;
}

function CardItemOffers({ iconItem, text }: IItemsOffers) {
	return (
		<div className={styles.cardItemOffer}>
			<div className="w-36 h-36 flex items-center justify-center">
				<img src={iconItem} alt="iconOffer" className={styles.imgItemOffer} />
			</div>
			<p className={styles.textItemOffer}>{text}</p>
		</div>
	);
}

export default CardItemOffers;
