import { useContext } from "react";
import cn from "classnames";
import { AppContext } from "../../../contexts/AppContext";

import styles from "./Products.module.css";
import imagePT from "../../../assets/images/pt/pt-image-2.png";
import imageES from "../../../assets/images/es/es-image-2.png";
import { ViewportProps } from "../viewport.interface";

function StoresProducts({ inViewport, forwardedRef }: ViewportProps) {
	const { country, translate } = useContext(AppContext);

	return (
		<section id="products" ref={forwardedRef}>
			<div className={styles.productsBackground}>
				<div className="container">
					<div className={styles.productsTitleCols}>
						<div
							className={
								inViewport
									? cn(styles.animationStart, styles.animationEnd)
									: styles.animationStart
							}
						>
							<h1 className={styles.productsTitle}><b>{translate("products.title")}</b></h1>
						</div>
					</div>
					<div className={styles.productsTextCols}>
						<p className={styles.productsText}>{translate("products.subtitle")}</p>
						<img src={country === "br" ? imagePT : imageES} alt="" />
					</div>
				</div>
			</div>
		</section>
	);
}

export default StoresProducts;
