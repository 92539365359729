import { useContext } from "react";
import handleViewport from "react-in-viewport";
import { AppContext } from "../../../contexts/AppContext";
import FormRegisterDriversCO from "../FormRegisterDrivers/FormRegisterDriversCO";
import RegisterDriversCO from "./RegisterDriverCO";

const ViewportRegisterCO = handleViewport(RegisterDriversCO);
const ViewportFormDriversCO = handleViewport(FormRegisterDriversCO);

function DriversRegister() {
	const { country, countryCodes, registerScreen } = useContext(AppContext);

	if (country === countryCodes.CO && !registerScreen) return <ViewportRegisterCO />;
	if (country === countryCodes.CO && registerScreen) return <ViewportFormDriversCO />;

	return <> </>;
}

export default DriversRegister;
