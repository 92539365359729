import { useState } from "react";
import { dataFileDocument } from "../../contexts/FormContext";
import xClose from "../../assets/images/xClose.png";
import xWhite from "../../assets/images/xWhite.png";
import styles from "./ProgressBar.module.css";
import CircularStatic from "./CircularProgress";

interface ProgressBarProps{
    sideTitle: string;
    onDelete: () => void;
    file?: dataFileDocument;
}

function ProgressBar({ sideTitle, onDelete, file }: ProgressBarProps) {
	const [zoomImage, setZoomImage] = useState(false);

	return (
		<div>
			{ !!(file?.progress) && (
				<div className={styles.cardImageTitle}>
					<div className="flex items-center h-3/4">
						<CircularStatic value={file?.progress} />
					</div>
					<div>
						<p className="font-bold text-base text-center">Validando</p>
					</div>
				</div>
			)}
			{(file) && !(file?.progress) && (
				<div className={styles.cardImageTitle}>
					<div className={styles.containerButtonClose}>
						<button className={styles.xClose} type="button" onClick={onDelete}>
							<img src={xClose} alt="x" className="w-6 h-6" />
						</button>
					</div>
					<div id="preview" className={styles.containerUpDoc}>
						<button type="button" onClick={() => setZoomImage(true)} className="flex justify-center">
							<img src={file.preview} alt="" className="w-28 h-20 mt-3 mb-3" />
						</button>
						<p className={styles.sideTitleUpDoc}>{sideTitle === "Front" ? "Parte delantera" : "Parte trasera"} </p>
						<button type="button" onClick={onDelete} className={styles.textNewFile}>
							<u className="text-blue-600 font-bold">Cargar un nuevo archivo</u>
						</button>
					</div>
				</div>
			)}
			{ (file) && zoomImage && (
				<div className="flex items-center justify-center fixed z-40 w-full h-full top-0 left-0 bg-black bg-opacity-70">
					<div className="flex flex-col items-end">
						<div className={styles.containerButtonCloseWhite}>
							<button type="button" onClick={() => setZoomImage(false)} className="flex justify-end">
								<img src={xWhite} alt="x" className="w-12 h-12" />
							</button>
						</div>
						<img src={file.preview} alt="" />
					</div>
				</div>
			)}
		</div>
	);
}

export default ProgressBar;
