import { useContext } from "react";
import { AppContext } from "../../../contexts/AppContext";
import SelectDocument from "../SelectDocument";
import { TermsProps } from "../Terms.interface";

import styles from "../Terms.module.css";
import ViewPDF from "../../../components/ViewPDF";

function TermsCO({ doc = "conditions" }: TermsProps) {
	const { translate, countryCodes } = useContext(AppContext);

	const colombiaDocs = [
		{
			value: "conditions",
			name: translate("terms.document.conditions"),
		},
		{
			value: "privacy",
			name: translate("terms.document.privacy"),
		},
		{
			value: "transparency",
			name: translate("terms.document.transparency"),
		},
		{
			value: "sagrilaft",
			name: translate("terms.document.sagrilaft"),
		},
	];

	return (
		<div className="containerTerms">
			<div className={styles.termsCols}>
				<SelectDocument value={doc} options={colombiaDocs} />
				<div className={styles.termsTextContainer}>
					<h1 className={styles.termsTitle}><b>{translate(`terms.document.${doc}`)}</b></h1>
					<ViewPDF
						pdf={`${process.env.PUBLIC_URL}/docs/${countryCodes.CO}/${countryCodes.CO}-${doc}.pdf`}
					/>
				</div>
			</div>
		</div>
	);
}

export default TermsCO;
