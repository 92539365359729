import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import cx from "classnames";

import styles from "./Header.module.css";
import { AppContext } from "../../contexts/AppContext";
import { RoutesNames } from "../../constants";

function HeaderLinks() {
	const { pathname } = useLocation();
	const { country, translate } = useContext(AppContext);
	const mobile = window.screen.width <= 480;

	const msgTendero = country === "cl" ? "menu.link.storesCl" : "menu.link.stores";

	if (country === "la") {
		return (
			<ul className={styles.menu}>
				<li
					className={
						pathname === `/la/${RoutesNames.about}`
							? cx(styles.menuItem, styles.menuItemActive)
							: styles.menuItem
					}
				>
					<Link to={`/la/${RoutesNames.about}`}>{translate("menu.link.about")}</Link>
				</li>

				<li
					className={
						pathname === `/${country}/${RoutesNames.careers}`
							? cx(styles.menuItem, styles.menuItemActive)
							: styles.menuItem
					}
				>
					<Link to={`/${country}/${RoutesNames.careers}`}>{translate("Careers")}</Link>
				</li>
			</ul>
		);
	}
	return (
		<ul className={styles.menu}>
			<li
				className={
					pathname === `/${country}/${RoutesNames.stores}`
						? cx(styles.menuItem, styles.menuItemActive)
						: styles.menuItem
				}
			>
				{ !mobile ? (<Link to={`/${country}/${RoutesNames.stores}`}>{translate(msgTendero)}</Link>) : (
					<a href="https://chiper.co" target="_blank" rel="noreferrer">
						{translate(msgTendero)}
					</a>
				)}
			</li>
			{ country === "co" && (
				<li
					className={
						pathname === `/${country}/${RoutesNames.drivers}`
							? cx(styles.menuItem, styles.menuItemActive)
							: styles.menuItem
					}
				>
					<Link to={`/${country}/${RoutesNames.drivers}`}>{translate("menu.link.drivers")}</Link>
				</li>
			)}
			<li
				className={
					pathname === `/${country}/${RoutesNames.careers}`
						? cx(styles.menuItem, styles.menuItemActive)
						: styles.menuItem
				}
			>
				<Link to={`/${country}/${RoutesNames.careers}`}>{translate("menu.link.careers")}</Link>
			</li>

		</ul>
	);
}

export default HeaderLinks;
