import { useContext } from "react";
import handleViewport from "react-in-viewport";
import { AppContext } from "../../../contexts/AppContext";
import BannerDriverCO from "./BannerDriverCO";

const ViewportBannerCO = handleViewport(BannerDriverCO);

function DriversBanner() {
	const { country, countryCodes, registerScreen } = useContext(AppContext);

	if (country === countryCodes.CO && !registerScreen) return <ViewportBannerCO />;

	return <> </>;
}

export default DriversBanner;
