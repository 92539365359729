import MultiCarousel from "react-multi-carousel";
import { useContext } from "react";
import styles from "../CareersInterviewTips.module.css";
import firstInterviewAdvice from "../../../assets/images/imagesCareers/firstInterviewAdvice.svg";
import secondInterviewAdvice from "../../../assets/images/imagesCareers/secondInterviewAdvice.svg";
import thirdInterviewAdvice from "../../../assets/images/imagesCareers/thirdInterviewAdvice.svg";
import fourthInterviewAdvice from "../../../assets/images/imagesCareers/fourthInterviewAdvice.svg";
import fifthInterviewAdvice from "../../../assets/images/imagesCareers/fifthInterviewAdvice.svg";
import sixthInterviewAdvice from "../../../assets/images/imagesCareers/sixthInterviewAdvice.svg";
import seventhInterviewAdvice from "../../../assets/images/imagesCareers/seventhInterviewAdvice.svg";
import "react-multi-carousel/lib/styles.css";
import { AppContext } from "../../../contexts/AppContext";
import CardTips from "./cardTips";

function CareersInterviewTips() {
	const { translate } = useContext(AppContext);
	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 2,
			slidesToSlide: 2,
			partialVisibilityGutter: 30,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
			slidesToSlide: 2,
			partialVisibilityGutter: 30,
		},
		mobile: {
			breakpoint: { max: 484, min: 0 },
			items: 1,
			slidesToSlide: 1,
			partialVisibilityGutter: 30,
		},
	};

	return (
		<MultiCarousel
			swipeable
			draggable
			showDots
			ssr
			responsive={responsive}
			customTransition="transform 300ms ease-in-out"
			transitionDuration={500}
			removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
			containerClass={styles.customers}
			dotListClass={styles.customersDots}
		>
			<CardTips
				tipIcon={firstInterviewAdvice}
				tipTitle={translate("global.careers.tips.list.0.title")}
				tipBody={translate("global.careers.tips.list.0.body")}
			/>
			<CardTips
				tipIcon={secondInterviewAdvice}
				tipTitle={translate("global.careers.tips.list.1.title")}
				tipBody={translate("global.careers.tips.list.1.body")}
			/>
			<CardTips
				tipIcon={thirdInterviewAdvice}
				tipTitle={translate("global.careers.tips.list.2.title")}
				tipBody={translate("global.careers.tips.list.2.body")}
			/>
			<CardTips
				tipIcon={fourthInterviewAdvice}
				tipTitle={translate("global.careers.tips.list.3.title")}
				tipBody={translate("global.careers.tips.list.3.body")}
			/>
			<CardTips
				tipIcon={fifthInterviewAdvice}
				tipTitle={translate("global.careers.tips.list.4.title")}
				tipBody={translate("global.careers.tips.list.4.body")}
			/>
			<CardTips
				tipIcon={sixthInterviewAdvice}
				tipTitle={translate("global.careers.tips.list.5.title")}
				tipBody={translate("global.careers.tips.list.5.body")}
			/>
			<CardTips
				tipIcon={seventhInterviewAdvice}
				tipTitle={translate("global.careers.tips.list.6.title")}
				tipBody={translate("global.careers.tips.list.6.body")}
			/>
		</MultiCarousel>
	);
}

export default CareersInterviewTips;
