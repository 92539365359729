import { createContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { countryCodes, languages } from "../constants";
import useI18next from "../i18n/useI18next";

import { AppContextProps, AppProviderProps } from "./AppContext.interfaces";

const { gtag } = require("ga-gtag");

export const defaultValueAppContext: AppContextProps = {
	country: countryCodes.CO,
	countryCodes,
	language: languages.es,
	languages,
	registerScreen: false,
	changeRegisterScreen: () => {},
	translate: () => {},
	changeLanguage: () => {},
	changeCountry: () => {},
};

export const AppContext = createContext(defaultValueAppContext);

function AppProvider({ children }: AppProviderProps) {
	const location = useLocation();
	const { translate, changeI18nextLanguage } = useI18next();
	const [language, setLanguage] = useState("");
	const [country, setCountry] = useState(countryCodes.LA);
	const [registerScreen, setRegisterScreen] = useState(false);

	const changeLanguage = (lng: string) => {
		setLanguage(lng);
		changeI18nextLanguage(lng);
	};
	const changeCountry = (newCountry: string) => setCountry(newCountry);

	const changeRegisterScreen = (status: boolean) => {
		setRegisterScreen(status);
	};

	useEffect(() => {
		const { pathname } = location;
		if (pathname.match(`/${countryCodes.CO}`)) changeCountry(countryCodes.CO);
		if (pathname.match(`/${countryCodes.BR}`)) changeCountry(countryCodes.BR);
		if (pathname.match(`/${countryCodes.MX}`)) changeCountry(countryCodes.MX);
		if (pathname.match(`/${countryCodes.CL}`)) changeCountry(countryCodes.CL);

		gtag("event", "page_view", {
			poll_title: pathname,
		});
	}, [location]);

	useEffect(() => {
		if (country === countryCodes.BR) changeLanguage(languages.pt);
		if (country === countryCodes.CO) changeLanguage(languages.es);
		if (country === countryCodes.MX) changeLanguage(languages.es);
		if (country === countryCodes.LA) changeLanguage(languages.en);
		if (country === countryCodes.CL) changeLanguage(languages.es);
	}, [country]);

	const appContextMemo = useMemo(() => ({
		country,
		countryCodes,
		language,
		languages,
		registerScreen,
		changeRegisterScreen,
		translate,
		changeLanguage,
		changeCountry,
	}), [location, country, registerScreen]);

	return (
		<AppContext.Provider value={appContextMemo}>
			{children}
		</AppContext.Provider>
	);
}

export default AppProvider;
