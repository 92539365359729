import { useContext } from "react";
import { ViewportProps } from "../../Stores/viewport.interface";
import styles from "./OurPurpose.module.css";
import ourPurposeImg from "../../../assets/images/imagesCareers/ourPurposeImg.png";
import mobileOurPurpose from "../../../assets/images/imagesCareers/mobileOurPurpose.png";
import { AppContext } from "../../../contexts/AppContext";

function OurPurpose({ forwardedRef }: ViewportProps) {
	const { translate } = useContext(AppContext);
	const mobile = window.screen.width <= 480;

	return (
		<section className={styles.sectionOurPurpose} id="bannerCareers" ref={forwardedRef}>
			<div className={styles.containerOurPurpose}>
				<div className={styles.imgOurPurpose}>
					<img src={mobile ? mobileOurPurpose : ourPurposeImg} alt="imgPurpose" />
				</div>
				<div className={styles.textOurPurpose}>
					<h1>
						{translate("global.careers.body.body")}
					</h1>
				</div>
			</div>
		</section>
	);
}

export default OurPurpose;
