import { useContext } from "react";
import handleViewport from "react-in-viewport";
import { AppContext } from "../../../contexts/AppContext";
import ChiperServiceCO from "./ChiperServiceCO";

const ViewportServiceCO = handleViewport(ChiperServiceCO);

function ChiperServices() {
	const { country, countryCodes, registerScreen } = useContext(AppContext);

	if (country === countryCodes.CO && !registerScreen) return <ViewportServiceCO />;

	return <> </>;
}

export default ChiperServices;
