import { useContext } from "react";
import { AppContext } from "../../../contexts/AppContext";
import styles from "./InterviewTips.module.css";
import { ViewportProps } from "../../Stores/viewport.interface";
import fourthDots from "../../../assets/images/imagesCareers/fourthDots.png";
import CareersInterviewTips from "../../../components/Carousel/CareersInterviewTips";

function InterviewTips({ forwardedRef }: ViewportProps) {
	const { translate } = useContext(AppContext);
	const mobile = window.screen.width <= 480;

	return (
		<section className={styles.sectionInterviewTips} id="customers" ref={forwardedRef}>
			<div>
				<div className={styles.headerInterviewTips}>
					<img src={fourthDots} alt="imgPoints" />
					<h1>{translate("global.careers.tips.title")}</h1>
					<img src={fourthDots} alt="imgPoints" />
				</div>
				<div className={!mobile ? "container" : ""}>
					<div className={styles.customersCarouselCols}>
						<CareersInterviewTips />
					</div>
				</div>
			</div>
		</section>
	);
}

export default InterviewTips;
