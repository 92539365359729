import { Button } from "chiper-sb-ecommerce";
import { useContext } from "react";
import { ViewportProps } from "../../Stores/viewport.interface";
import styles from "./BannerCareers.module.css";
import firstImageCareers from "../../../assets/images/imagesCareers/firstImageCareers.png";
import { AppContext } from "../../../contexts/AppContext";

function CareersBanner({ forwardedRef }: ViewportProps) {
	const { translate } = useContext(AppContext);
	return (
		<section className={styles.sectionBannerCareers} id="bannerCareers" ref={forwardedRef}>
			<div className={styles.containerBannerCareers}>
				<div className={styles.bodyBannerCareers}>
					<h1 className={styles.titleBannerCareers}>{translate("global.careers.main.title")}</h1>
					<p className={styles.textBanner}>{translate("global.careers.main.subtitle")}</p>
					<Button className={styles.buttonApply} onClick={() => { window.open("https://chiper.bamboohr.com/jobs/"); }}>
						{translate("global.careers.button.applyNow")}
					</Button>
				</div>
				<div className={styles.containerImgBannerCareers}>
					<img src={firstImageCareers} alt="imgBanner" />
				</div>
			</div>
		</section>
	);
}

export default CareersBanner;
