import useMediaQuery from "../../../hooks/useMediaQuery";
import chiperNumbers from "../../../assets/images/imagesAboutUs/chiperNumbers.png";
import chiperInNumbersRd from "../../../assets/images/imagesAboutUs/chiperInNumbersRd.png";

function ChiperInNumbers() {
	const isDesktop = useMediaQuery("(min-width: 600px)");

	return (
		<section className="py-20 text-center">
			<img className="m-auto" src={isDesktop ? chiperNumbers : chiperInNumbersRd} alt="" />
		</section>
	);
}

export default ChiperInNumbers;
