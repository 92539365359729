import { useContext } from "react";
import handleViewport from "react-in-viewport";
import SEO from "../../components/SEO";
import { AppContext } from "../../contexts/AppContext";
import CareersBanner from "./BannerCareers";
import ChiperOffersCareers from "./ChiperOffers";
import ChiperPeople from "./ChiperPeople";
import ChiperPrinciples from "./ChiperPrinciples";
import InterviewTips from "./InterviewTips";
import OurBusinessCarrers from "./OurBusiness";
import OurPurpose from "./OurPurpose";
import StepsToApply from "./StepsToApply";

function Careers() {
	const { translate } = useContext(AppContext);
	const ViewportBannerCareers = handleViewport(CareersBanner);
	const ViewportOurBusiness = handleViewport(OurBusinessCarrers);
	const ViewportOurPurpose = handleViewport(OurPurpose);
	const ViewportChiperPrinciples = handleViewport(ChiperPrinciples);
	const ViewportChiperPeople = handleViewport(ChiperPeople);
	const ViewportInterviewTips = handleViewport(InterviewTips);
	const ViewportStepsCareers = handleViewport(StepsToApply);
	return (
		<div data-testid="CareersScreen">
			<SEO
				title={translate("seo.stores.title")}
				description={translate("seo.stores.description")}
			/>
			<ViewportBannerCareers />
			<ViewportOurBusiness />
			<ViewportOurPurpose />
			<ViewportChiperPrinciples />
			<ChiperOffersCareers />
			<ViewportChiperPeople />
			<ViewportInterviewTips />
			<ViewportStepsCareers />
		</div>
	);
}

export default Careers;
