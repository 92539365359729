import cn from "classnames";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../contexts/AppContext";

import styles from "./BestPrices.module.css";
import image from "../../../assets/images/image-3.png";
import { ViewportProps } from "../viewport.interface";

function StoresBestPrices({ inViewport, forwardedRef }: ViewportProps) {
	const { language, languages, translate } = useContext(AppContext);
	const [brushStyle, setBrushStyle] = useState("");

	useEffect(() => {
		if (language === languages.es) setBrushStyle(styles.bestPricesBrushES);
		if (language === languages.pt) setBrushStyle(styles.bestPricesBrushPT);
	}, [language]);

	return (
		<section id="best-prices" ref={forwardedRef}>
			<div className={styles.bestPricesBackground}>
				<div className="container">
					<div className={styles.bestPricesImageCols}>
						<img src={image} alt="" />
					</div>
					<div className={styles.bestPricesTitleCols}>
						<div className="overflow-hidden">
							<div
								className={
									inViewport
										? cn(styles.animationStart, styles.animationEnd)
										: styles.animationStart
								}
							>
								<h1 className={styles.bestPricesTitle}><b>{translate("bestPrices.title")}</b></h1>
							</div>
						</div>
					</div>
					<div className={styles.bestPricesSubtitleCols}>
						<p className={styles.bestPricesSubtitle}>
							<span>{translate("bestPrices.subtitle.first")}</span>
							<span className={brushStyle}>{translate("bestPrices.subtitle.brush")}</span>
							<span>{translate("bestPrices.subtitle.last")}</span>
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default StoresBestPrices;
