import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { AppContext } from "../../contexts/AppContext";

import ImagePT from "../../assets/images/pt/pt-image-2.png";
import ImageES from "../../assets/images/es/es-image-2.png";

function HeadTags() {
	const { translate, language, languages } = useContext(AppContext);
	const [twitterOgImage, setTwitterOgImage] = useState(ImageES);

	useEffect(() => {
		if (language === languages.pt) setTwitterOgImage(ImagePT);
	}, [language]);

	return (
		<Helmet titleTemplate="Chiper | %s">
			<html lang={language} />
			<meta name="description" content={translate("banner.qrcode.text")} />

			{/* Twitter Card data */}
			<meta
				name="twitter:title"
				id="twitter-title"
				content={`CHIPER | ${translate("banner.title.underline")} ${translate("banner.title")}}`}
			/>
			<meta name="twitter:description" content={translate("banner.qrcode.text")} />
			<meta
				name="og:title"
				id="og-title"
				content={`CHIPER | ${translate("banner.title.underline")} ${translate("banner.title")}}`}
			/>
			<meta name="twitter:image" content={twitterOgImage} />

			{/* Open Graph data */}
			<meta property="og:url" content={window.location.href} />
			<meta name="og:description" content={translate("banner.qrcode.text")} />
			<meta property="og:image" content={twitterOgImage} />
			<link rel="canonical" href={window.location.href} />
		</Helmet>
	);
}

export default HeadTags;
