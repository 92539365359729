import { useContext } from "react";
import handleViewport from "react-in-viewport";
import { AppContext } from "../../../contexts/AppContext";
import FAQDriversCO from "./FAQDriversCO";

const ViewportFAQDriversCO = handleViewport(FAQDriversCO);

function FAQDrivers() {
	const { country, countryCodes, registerScreen } = useContext(AppContext);

	if (country === countryCodes.CO && !registerScreen) return <ViewportFAQDriversCO />;

	return <> </>;
}

export default FAQDrivers;
