/* eslint-disable global-require */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	InputField, Checkbox, Select, Button,
} from "chiper-sb-ecommerce";
import {
	ChangeEvent, useContext, useEffect, useRef, useState,
} from "react";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import Axios from "axios";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import styles from "../FormRegisterDrivers.module.css";
import UploadFilesRegisterCO from "./uploadFiles";
import addressIcon from "../../../../assets/images/addressIcon.svg";
import cellphoneIcon from "../../../../assets/images/cellphoneIcon.svg";
import cityIcon from "../../../../assets/images/cityIcon.svg";
import emailIcon from "../../../../assets/images/emailIcon.svg";
import genderIcon from "../../../../assets/images/genderIcon.svg";
import nameLastNameIcon from "../../../../assets/images/nameLastNameIcon.svg";
import vehicleIcon from "../../../../assets/images/vehicleIcon.svg";
import birthDateIcon from "../../../../assets/images/birthDateIcon.svg";
import documentRegister from "../../../../assets/images/documentRegister.png";
import SuccessfulRegistrationCO from "./successfulRegistration";
import { useFormContext } from "../../../../contexts/FormContext";
import { AppContext } from "../../../../contexts/AppContext";
import ToastMessageStyle from "../../../../components/ToastAlert";
import "react-datepicker/dist/react-datepicker.css";

export interface IDataLead {
  firstName: string;
  lastName: string;
  gender: string;
  birthDate: string;
  phoneNumber: string;
  email: string;
  leadTypeId: string;
  address: string;
  city: string;
  vehicle: {
    vehicleTypeId: string | null;
  };
}

function FormRegisterDriversCO() {
	const [isVehicleSelectionOrFinancing, setIsVehicleSelectionOrFinancing] = useState(true);
	const {
		name: nameDriver,
		changeNameRegisterDriverForm,
		lastName: lastNameDriver,
		changeLastNameRegisterDriverForm,
		cellPhone: cellPhoneDriver,
		changeCellphoneRegisterDriverForm,
		city: cityDriver,
		changeCityRegisterDriverForm,
		driverNoVehicle,
		changeDriverNoVehicle,
		driverWithVehicle,
		changeDriverWithVehicle,
		acceptTyC,
		changeAcceptTyC,
		leadIdUploadDoc,
		setLeadIdUploadDoc,
		leadTypeId: leadTypeIdDriver,
		changeLeadTypeIdRegisterDriverForm,
		leadId: leadIdDriver,
		changeLeadIdRegisterDriverForm,
		registrationFullComplete,
		screenStartUploadDocuments,
		setScreenStartUploadDocuments,
		renderImageStep2,
	} = useFormContext();

	const { REACT_APP_LANDING_API } = process.env;

	const { changeRegisterScreen } = useContext(AppContext);

	const handleOnClick = () => {
		changeRegisterScreen(false);
	};

	const formRef = useRef<HTMLFormElement>(null);

	const {
		control, handleSubmit, errors, setValue,
	} = useForm({
		shouldUnregister: false,
	});
	const mobile = window.screen.width <= 480;

	const [step, setStep] = useState(0);
	const [next, setNext] = useState(false);

	// FORM
	const [name, setName] = useState("");
	const [lastName, setLastName] = useState("");
	const [gender, setGender] = useState("");
	const [genderSpanish, setGenderSpanish] = useState("");
	const [birthDate, setBirthDate] = useState("");
	const [datePicker, setDatePicker] = useState<Date | null>(null);
	const [cellPhone, setCellPhone] = useState("");
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState(false);
	const [leadTypeId, setLeadTypeId] = useState("");
	const [vehicle, setVehicle] = useState("");
	const [vehicleType, setVehicleType] = useState("");
	const [vehicleTypeId, setVehicleTypeId] = useState("");
	const [leadId, setLeadId] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [vehicleYes, setVehicleYes] = useState(false);
	const [vehicleNo, setVehicleNo] = useState(false);

	const [dataResp, setDataResp] = useState<boolean | any>();
	const [ageNotAllowed, setAgeNotAllowed] = useState(false);

	const [checkBoxTyC, setCheckBoxTyC] = useState(false);
	const [registrationComplete, setRegistrationComplete] = useState(false);

	const getLeadTypeId = async () => {
		Axios.get(`${REACT_APP_LANDING_API}lead-type`)
			.then((resp: any) => {
				changeLeadIdRegisterDriverForm(resp?.data[Number(leadTypeId)]?.id);
			})
			.catch(() => {});
	};

	const getVehicleType = async () => {
		Axios.get(`${REACT_APP_LANDING_API}vehicle-type`)
			.then((resp: any) => {
				switch (vehicle) {
					case "0":
						setVehicleType("Camioneta");
						return setVehicleTypeId(resp?.data[0]?.id);
					case "1":
						setVehicleType("Otro");
						return setVehicleTypeId(resp?.data[1]?.id);
					case "2":
						setVehicleType("Moto carguero");
						return setVehicleTypeId(resp?.data[2]?.id);
					case "3":
						setVehicleType("Automóvil");
						return setVehicleTypeId(resp?.data[3]?.id);
					default:
						return null;
				}
			})
			.catch(() => {});
	};

	const validateEmail = (strEmail: string) => /\S+@\S+\.\S+/.test(strEmail);

	const yearsDatePicker = range(1890, getYear(new Date()) + 1, 1);

	const monthsDatePicker = [
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	];

	const formatBirthDate = () => {
		if (datePicker) {
			const year = datePicker.getFullYear().toString();
			const month = (1 + datePicker.getMonth()).toString();
			const day = datePicker.getDate().toString();
			return setBirthDate(
				`${day.length > 1 ? day : `0${day}`}/${month.length > 1 ? month : `0${month}`}/${year}`,
			);
		}
	};

	const calculateAge = (birthDateInput: Date) => {
		const currentDay = new Date();
		const birthday = new Date(birthDateInput);
		let age = currentDay.getFullYear() - birthday.getFullYear();
		const month = currentDay.getMonth() - birthday.getMonth();
		if (month < 0 || (month === 0 && currentDay.getDate() < birthday.getDate())) {
			// eslint-disable-next-line no-plusplus
			age--;
		}
		return age;
	};

	const verifyAge = (value: Date) => {
		if (value) {
			const age = calculateAge(value);
			if (age <= 17) {
				setAgeNotAllowed(true);
			}
			if (age >= 18) {
				setAgeNotAllowed(false);
			}
		}
	};

	const formatCellphone = (value: string) => {
		if (value) {
			return `(${value.substring(0, 3)}) ${value.substring(3, 6)}-${value.substring(6, 10)}`;
		}
	};

	const postDataLead = async () => {
		const body: IDataLead = {
			firstName: name,
			lastName,
			gender,
			birthDate,
			phoneNumber: cellPhone,
			email,
			leadTypeId: leadId ?? "",
			address,
			city,
			vehicle: {
				vehicleTypeId: vehicleNo ? null : vehicleTypeId,
			},
		};

		Axios.post(`${REACT_APP_LANDING_API}lead`, body)
			.then((resp: any) => {
				setDataResp(resp.data);
			})
			.catch((e: any) => {
				setScreenStartUploadDocuments(false);
				if (e?.response?.data?.statusCode === 500 || e?.response?.data?.statusCode === 503) {
					toast.error(
						<ToastMessageStyle message="El registro no pudo completarse. Por favor, revise los datos ingresados." />,
						{
							position: toast.POSITION.TOP_CENTER,
						},
					);
				} else {
					toast.error(<ToastMessageStyle message={e?.response?.data?.message} />, {
						position: toast.POSITION.TOP_CENTER,
					});
				}
			});
		setScreenStartUploadDocuments(true);
		setStep(0);
	};

	const onFormSubmit = () => {
		setIsVehicleSelectionOrFinancing(true);
		setAgeNotAllowed(false);

		if (!birthDate) {
			setAgeNotAllowed(true);
			return;
		}

		if (vehicleNo || vehicleYes) {
			setNext(true);
			return;
		}

		setIsVehicleSelectionOrFinancing(false);
	};

	const stepUploadDocuments = () => {
		postDataLead();
	};

	const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
		if (!validateEmail(e.target.value)) {
			setEmailError(true);
		} else {
			setEmailError(false);
		}
	};

	useEffect(() => {
		if (vehicleYes || vehicleNo) {
			getLeadTypeId();
			getVehicleType();
		}
		if (!next) {
			setStep(1);
		} else {
			setStep(2);
		}
		if (next && screenStartUploadDocuments) {
			setStep(0);
		}
	}, [vehicleYes, vehicleNo, next, screenStartUploadDocuments]);

	useEffect(() => {
		setName(nameDriver);
		setValue("nombre", nameDriver);
		setLastName(lastNameDriver);
		setValue("apellido", lastNameDriver);
		setCellPhone(cellPhoneDriver);
		setValue("celular", cellPhoneDriver);
		setCity(cityDriver);
		setValue("ciudad", cityDriver);
		setVehicleYes(driverWithVehicle);
		setVehicleNo(driverNoVehicle);
		setCheckBoxTyC(acceptTyC);
		setLeadTypeId(leadTypeIdDriver);
		setLeadId(leadIdDriver);
	}, [
		nameDriver,
		lastNameDriver,
		cellPhoneDriver,
		cityDriver,
		driverWithVehicle,
		driverNoVehicle,
		acceptTyC,
		leadTypeIdDriver,
		leadIdDriver,
	]);

	useEffect(() => {
		if (dataResp) {
			setLeadIdUploadDoc(dataResp?.id);
		}
	}, [dataResp, leadIdUploadDoc]);

	useEffect(() => {
		setRegistrationComplete(registrationFullComplete);
		if (registrationComplete) {
			setScreenStartUploadDocuments(false);
			setStep(8);
		}
	}, [registrationComplete, registrationFullComplete]);

	useEffect(() => {
		formatBirthDate();
	}, [datePicker, birthDate]);

	useEffect(() => {
		if (vehicleNo || vehicleYes) {
			setIsVehicleSelectionOrFinancing(true);
		}
	}, [vehicleNo, vehicleYes]);

	return (
		<section id="registerForm" className={styles.registerFormSection}>
			<div className={styles.registerContainer}>
				<div className={styles.containertitelAndSteps}>
					<p className={styles.titleForm}>
						{!screenStartUploadDocuments ? "Datos personales" : "Documentación"}
					</p>
					<div className={styles.containerSteps}>
						<li className={styles.steps1}>1</li>
						<div className={styles.lineSteps} />
						<li
							className={
								screenStartUploadDocuments || registrationComplete
									? styles.steps
									: styles.disabledSteps
							}
						>
              2
						</li>
						<div className={styles.lineSteps} />
						<li className={registrationComplete ? styles.steps : styles.disabledSteps}>3</li>
					</div>
				</div>
				{screenStartUploadDocuments && renderImageStep2 && (
					<div className={mobile ? "hidden" : "flex flex-col items-center px-8 pt-4"}>
						<img src={documentRegister} alt="imageDoc" />
					</div>
				)}
				<div className={styles.containerRegisterForm}>
					{step === 1 && (
						<form onSubmit={handleSubmit(onFormSubmit)} ref={formRef} data-testid="formDrivers">
							<p className={styles.subTitleForm}>
                Introduce tu nombre completo, tal cual como aparece en tu documento de identidad.
							</p>
							<div className={styles.firstGroupInputForm}>
								<Controller
									render={({ onChange }: ControllerRenderProps) => (
										<InputField
											type="text"
											placeholder="Nombre"
											errorMessage={errors?.nombre?.message}
											value={name}
											data-testid="nombre"
											onChange={(e: ChangeEvent<HTMLInputElement>) => {
												changeNameRegisterDriverForm(e.target.value);
											}}
											containerClassName="mt-3"
										/>
									)}
									name="nombre"
									defaultValue={name}
									control={control}
									rules={{
										required: {
											value: true,
											message: "Campo obligatorio",
										},
									}}
								/>
								<Controller
									render={({ onChange }: ControllerRenderProps) => (
										<InputField
											type="text"
											placeholder="Apellido"
											errorMessage={errors?.apellido?.message}
											value={lastName}
											data-testid="apellido"
											onChange={(e: ChangeEvent<HTMLInputElement>) => {
												changeLastNameRegisterDriverForm(e.target.value);
											}}
											containerClassName="mt-3"
										/>
									)}
									defaultValue={lastName}
									control={control}
									name="apellido"
									rules={{
										required: {
											value: true,
											message: "Campo obligatorio",
										},
									}}
								/>
								<Controller
									as={(
										<InputField
											type="text"
											placeholder="Correo"
											errorMessage={emailError ? "Correo incorrecto" : errors?.correo?.message}
											value={email}
											onInput={handleChangeEmail}
											containerClassName="mt-3"
											data-testid="correo"
										/>
									)}
									defaultValue={email}
									control={control}
									name="correo"
									rules={{
										required: {
											value: true,
											message: "Campo obligatorio",
										},
									}}
								/>
								<Controller
									as={(
										<InputField
											type="text"
											placeholder="Dirección"
											errorMessage={errors?.direccion?.message}
											value={address}
											onInput={(e: ChangeEvent<HTMLInputElement>) => setAddress(e.target.value)}
											containerClassName="mt-3"
											data-testid="direccion"
										/>
									)}
									defaultValue={address}
									control={control}
									name="direccion"
									rules={{
										required: {
											value: true,
											message: "Campo obligatorio",
										},
									}}
								/>
							</div>
							<div className={styles.secondGroupInputForm}>
								<div className={styles.containerInputs}>
									<Controller
										as={(
											<InputField
												type="number"
												placeholder="Celular"
												containerClassName={mobile ? "mt-3" : "mt-0"}
												errorMessage={errors?.celular?.message}
												mask="999 999-9999"
												value={cellPhone}
												data-testid="celular"
												onInput={(event: ChangeEvent<HTMLInputElement>) => {
													const phoneNumber = event.target.value.replace(/-|\(|\)| |_/g, "");
													if (phoneNumber.length <= 10) {
														changeCellphoneRegisterDriverForm(phoneNumber);
													}
												}}
											/>
										)}
										defaultValue={cellPhone}
										control={control}
										name="celular"
										rules={{
											required: {
												value: true,
												message: "Campo obligatorio",
											},
										}}
									/>
								</div>
								<div className={styles.containerInputs}>
									<Controller
										render={({ onChange }: ControllerRenderProps) => (
											<InputField
												type="text"
												placeholder="Ciudad"
												errorMessage={errors?.ciudad?.message}
												value={city}
												data-testid="ciudad"
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													changeCityRegisterDriverForm(e.target.value);
												}}
											/>
										)}
										defaultValue={city}
										control={control}
										name="ciudad"
										rules={{
											required: {
												value: true,
												message: "Campo obligatorio",
											},
										}}
									/>
								</div>
							</div>
							<div className={styles.thirdGroupInputForm}>
								<div className={styles.containerInputs} data-testid="fechaDeNacimiento">
									<ReactDatePicker
										renderCustomHeader={({
											date,
											changeYear,
											changeMonth,
											decreaseMonth,
											increaseMonth,
											prevMonthButtonDisabled,
											nextMonthButtonDisabled,
										}) => (
											<div
												style={{
													margin: 10,
													display: "flex",
													justifyContent: "space-between",
												}}
											>
												<button
													type="button"
													className=""
													onClick={decreaseMonth}
													disabled={prevMonthButtonDisabled}
												>
													{"<"}
												</button>
												<select
													value={monthsDatePicker[getMonth(date)]}
													onChange={({ target: { value } }) => {
														changeMonth(monthsDatePicker.indexOf(value));
													}}
												>
													{monthsDatePicker.map((option) => (
														<option key={option} value={option}>
															{option}
														</option>
													))}
												</select>
												<select
													value={getYear(date)}
													onChange={(e: ChangeEvent<HTMLSelectElement>) => {
														changeYear(parseFloat(e.target.value));
													}}
												>
													{yearsDatePicker.map((option) => (
														<option key={option} value={option}>
															{option}
														</option>
													))}
												</select>
												<button
													type="button"
													onClick={increaseMonth}
													disabled={nextMonthButtonDisabled}
												>
													{">"}
												</button>
											</div>
										)}
										dateFormat="dd/MM/yyyy"
										selected={datePicker}
										onChange={(date: Date | null | any) => {
											setDatePicker(date);
											verifyAge(date);
										}}
										placeholderText="Fecha de nacimiento"
										className={
											ageNotAllowed
												? "pl-2.5 w-full h-12 border-inherit border rounded-lg border-red-500"
												: "pl-2.5 w-full h-12 border-inherit border rounded-lg"
										}
										popperClassName={styles.datePickerPopper}
										locale={es}
									/>
									{ageNotAllowed && (
										<p className={styles.textAgeNotAllowed}>
                      Disponible solo para mayores de 18 años
										</p>
									)}
								</div>
								<div className={styles.containerInputs}>
									<Controller
										control={control}
										name="genero"
										defaultValue={gender}
										rules={{
											required: {
												value: true,
												message: "Campo obligatorio",
											},
										}}
										as={(
											<Select label="Género" errorMessage={errors?.genero?.message} data-testid="genero">
												<option value="" disabled>
                          Género
												</option>
												<option value="Masculine">Masculino</option>
												<option value="Feminine">Femenino</option>
											</Select>
										)}
										onInput={(e: ChangeEvent<HTMLInputElement>) => {
											setGender(e.target.value);
											if (e.target.value === "Masculine") {
												setGenderSpanish("Masculino");
											}
											if (e.target.value === "Feminine") {
												setGenderSpanish("Femenino");
											}
										}}
									/>
								</div>
							</div>
							<div className={styles.fourthGroupInputForm}>
								<p className={styles.vehicleQuestion}>
                  ¿Tienes un vehículo o necesitas financiar uno?*
								</p>
								<div className={styles.containerFourthInputs}>
									<div className={styles.checkboxRound}>
										<Checkbox
											name="radioVehicle"
											type="radio"
											checked={vehicleYes}
											onChange={() => {
												changeDriverWithVehicle(true);
												changeDriverNoVehicle(false);
												changeLeadTypeIdRegisterDriverForm("0");
											}}
										/>
										<p className={styles.bodyTextVehicle}>Tengo vehículo</p>
									</div>
									<div className={styles.checkboxRound}>
										<Checkbox
											name="radioNoVehicle"
											type="radio"
											checked={vehicleNo}
											onChange={() => {
												changeDriverNoVehicle(true);
												changeDriverWithVehicle(false);
												changeLeadTypeIdRegisterDriverForm("1");
											}}
										/>
										<p className={styles.bodyTextVehicle}>Necesito financiar</p>
									</div>
								</div>
								{!vehicleNo && (
									<Controller
										control={control}
										name="vehículo"
										defaultValue={vehicle}
										rules={{
											required: {
												value: true,
												message: "Campo obligatorio",
											},
										}}
										onInput={(e: ChangeEvent<HTMLInputElement>) => {
											setVehicle(e.target.value);
										}}
										as={(
											<Select
												label="Tipo de vehículo"
												className={mobile ? "mt-5 mb-0" : "mt-5 mb-7"}
												data-testid="vehículo"
											>
												<option value="" disabled>
                          Tipo de vehículo
												</option>
												<option value="3">Automóvil</option>
												<option value="0">Camioneta</option>
												<option value="2">Moto carguero</option>
											</Select>
										)}
									/>
								)}
								<div className={styles.checkboxRound}>
									<Checkbox
										name="checkboxTyC"
										type="checkbox"
										checked={checkBoxTyC}
										onChange={() => changeAcceptTyC(!checkBoxTyC)}
									/>
									<p className={styles.bodyTextDriver}>
                    Autorizo los{" "}
										<b>
											<u>
												<a
													href={require("../../../../assets/TermsDriversCO/TyCDriversCO.pdf")}
													target="_blank"
													rel="noreferrer"
												>
                          términos y condiciones
												</a>
											</u>
										</b>{" "}
                    del uso de la plataforma y la{" "}
										<b>
											<u>
												<a
													href={require("../../../../assets/TermsDriversCO/DatosPersonalesDriversCO.pdf")}
													target="_blank"
													rel="noreferrer"
												>
                          política de tratamiento de datos personales
												</a>
											</u>
										</b>{" "}
                    .
									</p>
								</div>
								<div className={styles.buttonsForm}>
									<Button type="button" primary={false} onClick={handleOnClick} data-testid="btnVolver">
                    Volver
									</Button>
									<Button
										type="submit"
										className={styles.registerBtn}
										disabled={!checkBoxTyC || ageNotAllowed || emailError}
									>
                    Continuar
									</Button>
								</div>
								{!isVehicleSelectionOrFinancing && (
									<p className={styles.errorOptions}>
                    Por favor revise que todos los campos se encuentren diligenciados*
									</p>
								)}
							</div>
						</form>
					)}
					{step === 2 && (
						<div className={styles.containerConfirmData}>
							<p className={styles.subTitleForm}>¿Son correctos los datos ingresados?</p>
							<div className={styles.cardData}>
								<img src={nameLastNameIcon} alt="img" />
								<div>
									<p className={styles.cardTitle}>Nombre y apellido</p>
									<p className={styles.cardBody}>
										<b>
											{name} {lastName}
										</b>
									</p>
								</div>
							</div>
							<div className={styles.cardData}>
								<img src={emailIcon} alt="img" />
								<div>
									<p className={styles.cardTitle}>Correo</p>
									<p className={styles.cardBody}>
										<b>{email}</b>
									</p>
								</div>
							</div>
							<div className={styles.cardData}>
								<img src={cellphoneIcon} alt="img" />
								<div>
									<p className={styles.cardTitle}>Celular</p>
									<p className={styles.cardBody}>
										<b>{formatCellphone(cellPhone)}</b>
									</p>
								</div>
							</div>
							<div className={styles.cardData}>
								<img src={addressIcon} alt="img" />
								<div>
									<p className={styles.cardTitle}>Dirección</p>
									<p className={styles.cardBody}>
										<b>{address}</b>
									</p>
								</div>
							</div>
							<div className={styles.cardData}>
								<img src={cityIcon} alt="img" />
								<div>
									<p className={styles.cardTitle}>Ciudad</p>
									<p className={styles.cardBody}>
										<b>{city}</b>
									</p>
								</div>
							</div>
							<div className={styles.cardData}>
								<img src={birthDateIcon} alt="img" />
								<div>
									<p className={styles.cardTitle}>Fecha de nacimiento</p>
									<p className={styles.cardBody}>
										<b>{birthDate}</b>
									</p>
								</div>
							</div>
							<div className={styles.cardData}>
								<img src={genderIcon} alt="img" />
								<div>
									<p className={styles.cardTitle}>Género</p>
									<p className={styles.cardBody}>
										<b>{genderSpanish}</b>
									</p>
								</div>
							</div>
							<div className={styles.cardData}>
								<img src={vehicleIcon} alt="img" />
								<div>
									<p className={styles.cardTitle}>{!vehicleNo ? "Tipo de vehículo" : ""}</p>
									<p className={styles.cardBody}>
										<b>{!vehicleNo ? vehicleType : "Necesito financiar"}</b>
									</p>
								</div>
							</div>
							<div className={styles.buttonsForm}>
								<Button type="button" primary={false} onClick={() => setNext(false)}>
                  Cambiar
								</Button>
								<Button
									type="submit"
									className={styles.registerBtn}
									onClick={() => stepUploadDocuments()}
								>
                  Continuar
								</Button>
							</div>
						</div>
					)}
					{screenStartUploadDocuments && <UploadFilesRegisterCO />}
				</div>
				{registrationFullComplete && <SuccessfulRegistrationCO />}
			</div>
		</section>
	);
}

export default FormRegisterDriversCO;
