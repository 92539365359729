import styles from "../Footer.module.css";
import FacebookImg from "../../../assets/images/facebook.svg";
import InstagramImg from "../../../assets/images/instagram.svg";
import YoutubeImg from "../../../assets/images/youtube.svg";

interface SiteProps {
  url: string;
  utm_source: string;
  utm_medium: string;
  utm_term: string;
  utm_content: string;
  utm_campaign: string;
}

interface FooterSocialLinksProps {
  facebook?: SiteProps;
  instagram?: SiteProps;
  youtube?: SiteProps;
}

function FooterSocialLinks({ facebook, instagram, youtube }: FooterSocialLinksProps) {
	function getURL({
		url, utm_source, utm_medium, utm_term, utm_content, utm_campaign,
	}: SiteProps) {
		return `${url}?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_term=${utm_term}&utm_content=${utm_content}&utm_campaign=${utm_campaign}`;
	}

	return (
		<>
			{facebook && (
				<a target="_blank" href={getURL(facebook)} className={styles.socialLink} rel="noreferrer">
					<img src={FacebookImg} alt="facebook" width={25} />
				</a>
			)}
			{instagram && (
				<a target="_blank" href={getURL(instagram)} className={styles.socialLink} rel="noreferrer">
					<img src={InstagramImg} alt="instagram" width={25} />
				</a>
			)}
			{youtube && (
				<a target="_blank" href={getURL(youtube)} className={styles.socialLink} rel="noreferrer">
					<img src={YoutubeImg} alt="youtube" width={25} />
				</a>
			)}
		</>
	);
}

export default FooterSocialLinks;
