import useMediaQuery from "../../../hooks/useMediaQuery";
import secondDots from "../../../assets/images/imagesCareers/secondDots.png";
import speedTruck from "../../../assets/images/imagesAboutUs/speedTruck.png";
import cest from "../../../assets/images/imagesAboutUs/cest.png";
import calculator from "../../../assets/images/imagesAboutUs/calculator.png";

function ExperienceChiper() {
	const isDesktop = useMediaQuery("(min-width: 600px)");

	return (
		<div
			className={`bg-chiper-gray justify-center py-20 ${
				isDesktop ? "flex align-left " : "flex-col align-center px-5"
			}`}
		>
			<div className={isDesktop ? "p-12" : "p-0"} style={{ maxWidth: 694 }}>
				<p className={` ${isDesktop ? "text-left" : "text-center"}`}>How we do it?</p>
				<p className={`font-bold ${isDesktop ? "text-8xl text-left" : "text-6xl text-center"}`}>
          With chiper, corner store owners can replenish their store´s inventory through a complete
          digital experience
				</p>
			</div>
			<div style={{ maxWidth: 900, paddingLeft: isDesktop ? 90 : 0 }}>
				{isDesktop && (
					<div>
						<img src={secondDots} style={{ marginTop: 60 }} height={19} alt="" />
					</div>
				)}
				<div className="flex">
					<img className="mb-auto" src={speedTruck} alt="" />
					<div>
						<p className="font-bold text-2xl mt-10">Unrivaled Delivery Speed </p>
						<p className="text-2xl leading-10">
              Solving customer’s needs through the fastest order delivery
              available on the market
						</p>
					</div>
				</div>
				<div className="flex">
					<img className="mb-auto" src={cest} alt="" />
					<div>
						<p className="font-bold text-2xl mt-10">Modernizing Procurement </p>
						<p className="text-2xl leading-10">
              Supporting customer needs, through a carefully curated product portfolio, offering the
              widest selection on one platform
						</p>
					</div>
				</div>
				<div className="flex">
					<img className="mb-auto" src={calculator} alt="" />
					<div>
						<p className="font-bold text-2xl mt-10">Unbeatable Competitive Prices</p>
						<p className="text-2xl leading-10">
              Leveraging machine learning and automation increases our efficiencies, enabling higher
              margins, and creating opportunities to offer the most competitive prices
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ExperienceChiper;
