import styles from "./ChiperPrinciples.module.css";

interface IItems{
    numberItem: number;
    title: string;
    bodyText: string;
}

function ItemPrinciples({ numberItem, title, bodyText }: IItems) {
	return (
		<div className={styles.cardItem}>
			<li className={numberItem === 1 ? styles.numberItem1 : styles.numberItem}>
				{numberItem}
			</li>
			<div className={styles.bodyItem}>
				<p className={styles.titleItem}>
					<b>
						{title}
					</b>
				</p>
				<p className={styles.textItem}>
					{bodyText}
				</p>
			</div>
		</div>
	);
}

export default ItemPrinciples;
