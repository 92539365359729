import { Helmet } from "react-helmet";

interface SEOProps {
  title: string;
  description: string;
}

function SEO({ title, description }: SEOProps) {
	return (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={description} />
		</Helmet>
	);
}

export default SEO;
