import { useContext } from "react";
import cn from "classnames";
import { AppContext } from "../../../contexts/AppContext";
import styles from "./Need.module.css";
import image from "../../../assets/images/image-4.png";
import { ViewportProps } from "../viewport.interface";

function StoresNeed({ forwardedRef, inViewport }: ViewportProps) {
	const { translate } = useContext(AppContext);

	return (
		<section id="need" ref={forwardedRef}>
			<div className={styles.needBackground}>
				<div className="container">
					<div className={styles.needTitleCols}>
						<div className="overflow-hidden">
							<div
								className={
									inViewport
										? cn(styles.animationStart, styles.animationEnd)
										: cn(styles.animationStart)
								}
							>
								<h1 className={styles.needTitle}><b>{translate("need.title")}</b></h1>
							</div>
						</div>
					</div>
					<div className={styles.needSubtitleCols}>
						<p className={styles.needText}>{translate("need.subtitle")}</p>
						<div className={styles.needImage}>
							<img src={image} alt="" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default StoresNeed;
