import styles from "../AboutUs.module.css";
import banner from "../../../assets/images/imagesAboutUs/banner.png";

const transformingFutureBanner = () => (
	<div className={styles.sectionBanner}>
		<div className={styles.containerBanner}>
			<div className={styles.bodyBanner}>
				<h1 className={styles.titleBanner}>
            Chiper is the leading B2B e-commerce ecosystem for corner stores in Latin America
				</h1>
			</div>
			<div className={styles.containerImgBanner}>
				<img className="m-auto mr-0" src={banner} alt="imgBanner" />
			</div>
		</div>
	</div>
);

export default transformingFutureBanner;
