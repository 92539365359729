/* eslint-disable consistent-return */
import { Button } from "chiper-sb-ecommerce";
import { useContext, useEffect, useState } from "react";
import Axios from "axios";
import styles from "./uploadFiles.module.css";
import buenaIluminacion from "../../../../assets/images/buenaIluminacion.svg";
import datosLegibles from "../../../../assets/images/datosLegibles.svg";
import fotoDelDocumento from "../../../../assets/images/fotoDelDocumento.svg";
import checkRoundIcon from "../../../../assets/images/checkRoundIcon.svg";
import emptyRoundIcon from "../../../../assets/images/emptyRoundIcon.svg";
import fileUpload from "../../../../assets/images/fileUpload.svg";
import Paperclip from "../../../../assets/images/Paperclip.svg";
import uploadDocsMobile from "../../../../assets/images/uploadDocsMobile.png";
import documentRegister from "../../../../assets/images/documentRegister.png";
import { FormContext } from "../../../../contexts/FormContext";
import ScreenUploadDocuments from "./screenUploadFiles";

function UploadFilesRegisterCO() {
	const { REACT_APP_LANDING_API } = process.env;
	const {
		setFileIdUploadDoc,
		driverNoVehicle,
		fileTypeUploadDoc,
		setFileTypeUploadDoc,
		fileDocUploadBack,
		fileDocUploadFront,
		setFileDocUploadFront,
		setFileDocUploadBack,
		fileLicUploadFront,
		setFileLicUploadFront,
		fileLicUploadBack,
		setFileLicUploadBack,
		filePropertyUploadFront,
		setFilePropertyUploadFront,
		filePropertyUploadBack,
		setFilePropertyUploadBack,
		fileSOATUpload,
		setFileSOATUpload,
		setRegistrationFullComplete,
		setScreenStartUploadDocuments,
		setRenderImageStep2,
	} = useContext(FormContext);
	const [next, setNext] = useState(false);
	const [uploadDocument, setUploadDocument] = useState(false);
	const [step, setStep] = useState(0);
	const [verifications, setVerifications] = useState({
		IdDocument: false,
		DriverLicense: false,
		SOAT: false,
		PropertyCard: false,
	});
	const [isAllVerified, setIsAllVerified] = useState(false);
	const mobile = window.screen.width <= 480;

	const screenUploadDocument = () => {
		setNext(true);
		setUploadDocument(true);
	};

	const getFileType = async () => {
		Axios.get(`${REACT_APP_LANDING_API}file-type`)
			.then((resp: any) => {
				switch (fileTypeUploadDoc) {
					case "SOAT":
						return setFileIdUploadDoc(resp?.data[0]?.id);
					case "DriverLicense":
						return setFileIdUploadDoc(resp?.data[1]?.id);
					case "PropertyCard":
						return setFileIdUploadDoc(resp?.data[2]?.id);
					case "IdDocument":
						return setFileIdUploadDoc(resp?.data[3]?.id);
					default:
						return null;
				}
			})
			.catch((e: any) => {
				console.log(e);
			});
	};

	const verifyCases = () => {
		setIsAllVerified(true);

		Object.keys(verifications).forEach((key) => {
			if (!verifications[key as "IdDocument" | "DriverLicense" | "PropertyCard" | "SOAT"]) {
				setIsAllVerified(false);
			}
		});
	};

	const subtitleByDocument = () => {
		if (!verifications.IdDocument) {
			return (
				<p className={styles.titleUploadFiles}>
          Sube una foto de <b>tu documento de identificación</b> donde se vea la información. Debes
          elegir entre cédula de ciudadanía o cédula de extranjería.
				</p>
			);
		}
		if (!verifications.DriverLicense) {
			return (
				<p className={styles.titleUploadFiles}>
          Sube una foto de <b>tu licencia de conducción</b> donde se vea la información.
				</p>
			);
		}
		if (!verifications.SOAT && driverNoVehicle) {
			return (
				<p className={styles.titleUploadFiles}>
					{"Los documentos fueron enviados o cargados con éxito. Para continuar, haz clic en \"terminar el registro\"."}
				</p>
			);
		}
		if (!verifications.SOAT) {
			return (
				<p className={styles.titleUploadFiles}>
          Sube una foto del <b>SOAT del vehículo</b> donde se vea la información.
				</p>
			);
		}
		if (!verifications.PropertyCard) {
			return (
				<p className={styles.titleUploadFiles}>
          Sube una foto <b>de la tarjeta de propiedad del vehículo</b> donde se vea la información.
				</p>
			);
		}
		if (isAllVerified) {
			return (
				<p className={styles.titleUploadFiles}>
					{"Los documentos fueron enviados o cargados con éxito. Para continuar, haz clic en \"terminar el registro\"."}
				</p>
			);
		}
	};

	const itemsType = {
		IdDocument: [
			{
				onChange: setFileDocUploadFront,
				sideTitle: "Front",
				file: fileDocUploadFront,
			},
			{
				onChange: setFileDocUploadBack,
				sideTitle: "Back",
				file: fileDocUploadBack,
			},
		],
		DriverLicense: [
			{
				onChange: setFileLicUploadFront,
				sideTitle: "Front",
				file: fileLicUploadFront,
			},
			{
				onChange: setFileLicUploadBack,
				sideTitle: "Back",
				file: fileLicUploadBack,
			},
		],
		SOAT: [
			{
				onChange: setFileSOATUpload,
				sideTitle: "Front",
				file: fileSOATUpload,
				isPdf: true,
			},
		],
		PropertyCard: [
			{
				onChange: setFilePropertyUploadFront,
				sideTitle: "Front",
				file: filePropertyUploadFront,
			},
			{
				onChange: setFilePropertyUploadBack,
				sideTitle: "Back",
				file: filePropertyUploadBack,
			},
		],
	};

	const screenStep = () => {
		setRegistrationFullComplete(true);
		setScreenStartUploadDocuments(false);
	};

	useEffect(() => {
		getFileType();
		if (!next) {
			setStep(1);
		} else {
			setStep(2);
		}
		if (next && uploadDocument) {
			setStep(3);
		}
	}, [fileTypeUploadDoc, uploadDocument, next]);

	useEffect(() => {
		verifyCases();
		subtitleByDocument();
	}, [verifications]);

	useEffect(() => {
		if (step === 2) {
			setRenderImageStep2(true);
		} else {
			setRenderImageStep2(false);
		}
	});

	return (
		<div className={styles.containerUploadFiles}>
			{step === 1 && (
				<div className={styles.uploadFiles}>
					<div className={mobile ? styles.imgCameraMobile : "hidden"}>
						<img src={uploadDocsMobile} alt="" />
					</div>
					<p className={styles.titleUploadFiles}>
            Ten en cuenta estas recomendaciones para cargar tu documentación. Adjunta solo
            documentos que estén en formato JPG, PNG o PDF.
					</p>
					<div className={styles.listUploadFiles}>
						<img src={buenaIluminacion} alt="imgIluminacion" />
						<div className={styles.textBodyUploadFiles}>
							<p className={styles.subtitleUploadFiles}>Buena iluminación</p>
							<p className={styles.bodyUploadFiles}>
                Asegúrate de contar con buena iluminación, no tener sombras, ni reflejos.
							</p>
						</div>
					</div>
					<div className={styles.listUploadFilesCenter}>
						<img src={datosLegibles} alt="imgDatosLegibles" style={{ maxWidth: "none" }} />
						<div className={styles.textBodyUploadFiles}>
							<p className={styles.subtitleUploadFiles}>Datos legibles</p>
							<p className={styles.bodyUploadFiles}>
                Específicamente tu nombre, fecha de nacimiento y número de identidad.
							</p>
						</div>
					</div>
					<div className={styles.listUploadFiles}>
						<img src={fotoDelDocumento} alt="imgDocument" />
						<div className={styles.textBodyUploadFiles}>
							<p className={styles.subtitleUploadFiles}>Foto del documento</p>
							<p className={styles.bodyUploadFiles}>El documento debe verse en su totalidad.</p>
						</div>
					</div>
					<div className={styles.buttonsUploadFiles1}>
						<Button
							className={styles.btnGreen}
							type="button"
							onClick={() => {
								setNext(true);
								setUploadDocument(false);
							}}
						>
              Continuar
						</Button>
					</div>
				</div>
			)}
			{step === 2 && (
				<div className={styles.uploadFiles}>
					<div className={styles.containerConfirmData}>
						{subtitleByDocument()}
						<div className={!mobile ? "hidden" : "flex flex-col items-center px-8"}>
							<img src={documentRegister} alt="imageDoc" />
						</div>
						<button
							type="button"
							className="w-full"
							onClick={() => {
								screenUploadDocument();
								setFileTypeUploadDoc("IdDocument");
							}}
						>
							<div className={styles.cardData}>
								<div className={styles.cardUploadFileTitle}>
									{verifications.IdDocument ? (
										<img src={checkRoundIcon} alt="img" />
									) : (
										<img src={emptyRoundIcon} alt="img" />
									)}
									<p className={styles.cardBody}>Tu documento de identificación</p>
								</div>
								{verifications.IdDocument ? (
									<img src={Paperclip} alt="img" />
								) : (
									<img src={fileUpload} alt="img" />
								)}
							</div>
						</button>
						<button
							type="button"
							className={verifications.IdDocument ? "w-full" : "w-full opacity-50"}
							onClick={() => {
								screenUploadDocument();
								setFileTypeUploadDoc("DriverLicense");
							}}
							disabled={!verifications.IdDocument}
						>
							<div className={styles.cardData}>
								<div className={styles.cardUploadFileTitle}>
									{verifications.DriverLicense ? (
										<img src={checkRoundIcon} alt="img" />
									) : (
										<img src={emptyRoundIcon} alt="img" />
									)}
									<p className={styles.cardBody}>Tu licencia de conducción</p>
								</div>
								{verifications.DriverLicense ? (
									<img src={Paperclip} alt="img" />
								) : (
									<img src={fileUpload} alt="img" />
								)}
							</div>
						</button>
						{!driverNoVehicle && (
							<button
								type="button"
								className={verifications.DriverLicense ? "w-full" : "w-full opacity-50"}
								onClick={() => {
									screenUploadDocument();
									setFileTypeUploadDoc("SOAT");
								}}
								disabled={!verifications.DriverLicense}
							>
								<div className={styles.cardData}>
									<div className={styles.cardUploadFileTitle}>
										{verifications.SOAT ? (
											<img src={checkRoundIcon} alt="img" />
										) : (
											<img src={emptyRoundIcon} alt="img" />
										)}
										<p className={styles.cardBody}>El SOAT</p>
									</div>
									{verifications.SOAT ? (
										<img src={Paperclip} alt="img" />
									) : (
										<img src={fileUpload} alt="img" />
									)}
								</div>
							</button>
						)}
						{!driverNoVehicle && (
							<button
								type="button"
								className={verifications.SOAT ? "w-full" : "w-full opacity-50"}
								onClick={() => {
									screenUploadDocument();
									setFileTypeUploadDoc("PropertyCard");
								}}
								disabled={!verifications.SOAT}
							>
								<div className={styles.cardData}>
									<div className={styles.cardUploadFileTitle}>
										{verifications.PropertyCard ? (
											<img src={checkRoundIcon} alt="img" />
										) : (
											<img src={emptyRoundIcon} alt="img" />
										)}
										<p className={styles.cardBody}>La tarjeta de propiedad</p>
									</div>
									{verifications.PropertyCard ? (
										<img src={Paperclip} alt="img" />
									) : (
										<img src={fileUpload} alt="img" />
									)}
								</div>
							</button>
						)}
					</div>
					<div className={styles.buttonsUploadFiles}>
						<Button type="button" primary={false} onClick={() => setNext(false)}>
              Volver
						</Button>
						{!driverNoVehicle ? (
							<Button type="button" disabled={!isAllVerified} onClick={() => screenStep()} className={isAllVerified ? styles.btnGreen : ""}>
                Terminar el registro
							</Button>
						) : (
							<Button
								disabled={!verifications.DriverLicense || !verifications.IdDocument}
								type="button"
								onClick={() => screenStep()}
								className={!verifications.DriverLicense || !verifications.IdDocument ? styles.btnGreen : ""}
							>
                Terminar el registro
							</Button>
						)}
					</div>
				</div>
			)}
			{step === 3 && (
				<ScreenUploadDocuments
					items={
						itemsType[fileTypeUploadDoc as "IdDocument" | "DriverLicense" | "PropertyCard" | "SOAT"]
					}
					onSuccess={() => {
						setVerifications({ ...verifications, [fileTypeUploadDoc]: true });
						setUploadDocument(false);
					}}
					onBack={(actual: boolean) => {
						setVerifications({ ...verifications, [fileTypeUploadDoc]: actual });
						setUploadDocument(false);
						setStep(2);
					}}
				/>
			)}
		</div>
	);
}

export default UploadFilesRegisterCO;
