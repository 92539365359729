import { useContext } from "react";
import handleViewport from "react-in-viewport";
import { AppContext } from "../../../contexts/AppContext";
import StoresHelpedES from "./HelpedES";

const ViewportHelped = handleViewport(StoresHelpedES);

function StoresHelped() {
	const { country, countryCodes } = useContext(AppContext);

	if (country === countryCodes.BR) return <>&nbsp;</>;

	return <ViewportHelped />;
}

export default StoresHelped;
