import { ChangeEvent, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../contexts/AppContext";
import styles from "./SelectDocument.module.css";

interface OptionProps {
  value: string;
  name: string;
}

interface SelectDocumentProps {
  value: string;
  options: OptionProps[];
}

function SelectDocument({ value, options }: SelectDocumentProps) {
	const {
		translate, changeCountry, country, changeLanguage, language,
	} = useContext(AppContext);
	const navigate = useNavigate();
	const detectUrl = window.location.pathname;

	function changeDoc(event: ChangeEvent<HTMLSelectElement>) {
		navigate(`${event.target.value}`);
	}

	useEffect(() => {
		if (detectUrl === "/br/terms/conditions") {
			changeCountry("br");
			changeLanguage("pt");
		}
	}, [detectUrl, language, country]);

	return (
		<div className={styles.termsSelectContainer}>
			<p className={styles.termsSelectDocLabel}>
				<strong>{translate("terms.select.label")}</strong>
			</p>
			<div className={styles.termsSelectDiv}>
				<select
					className={styles.termsSelect}
					name="doc"
					id="doc"
					value={value}
					onChange={changeDoc}
				>
					{options.map(({ value: opValue, name }) => (
						<option key={opValue} value={opValue}>
							{name}
						</option>
					))}
				</select>
			</div>
		</div>
	);
}

export default SelectDocument;
