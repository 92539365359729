/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext } from "react";
import { AppContext } from "../../../../contexts/AppContext";
import { ViewportProps } from "../../../Stores/viewport.interface";
import styles from "../VehicleCredit.module.css";
import pointsVehicle from "../../../../assets/images/pointsVehicle.png";
import vehicle2 from "../../../../assets/images/vehicle2.png";

function VehicleCreditCO({ forwardedRef }: ViewportProps) {
	const { language, languages, translate } = useContext(AppContext);

	return (
		<section id="vehicleCredit" className={styles.sectionCredit} ref={forwardedRef}>
			<div className="px-10">
				<div className={styles.firstGroupCredit}>
					<img src={pointsVehicle} className={styles.imgPoints} alt="pointsVehicle" />
					<div>
						<h1>
							<b>¿No tienes vehículo?</b>
						</h1>
						<h1>
							<b>¡No hay problema!</b>
						</h1>
					</div>
				</div>
				<div className={styles.secondGroupCredit}>
					<img src={vehicle2} alt="vehicle2" />
					<div className={styles.bodyVehicle}>
						<p>
							{" "}
              Transportando para Chiper puedes tener acceso a un crédito de manera sencilla, con
              cuotas accesibles, pocos trámites y todo incluido para transportar y, al finalizar tu
              crédito, ¡serás dueño de tu vehículo!
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default VehicleCreditCO;
