import { useContext } from "react";
import cn from "classnames";
import { AppContext } from "../../../../contexts/AppContext";
import styles from "../Helped.module.css";
import { ViewportProps } from "../../viewport.interface";

function StoresHelpedES({ forwardedRef, inViewport }: ViewportProps) {
	const { translate, country } = useContext(AppContext);
	const titleVideo = country === "cl" ? "Alejandra es una de las miles de almaceneras que cuentan con Chiper para abastecer su almacén al mejor precio." : "Alejandra es una de las miles de tenderas que cuentan con Chiper para abastecer su tienda al mejor precio.";

	return (
		<section id="helped" className={styles.helped} ref={forwardedRef}>
			<div className={styles.helpedBackground}>
				<div className="container">
					<div className={styles.helpedTitleCols}>
						<div className="overflow-hidden">
							<div
								className={
									inViewport
										? cn(styles.animationStart, styles.animationEnd)
										: styles.animationStart
								}
							>
								<h1 className={styles.helpedTitle}>
									<b>{translate("helped.title.CO")}</b>
								</h1>
								<h1>
									<b className={styles.underline}>{translate("helped.title.underline")}</b>
								</h1>
							</div>
						</div>
					</div>
					<div className={styles.helpedTextCols}>
						<p>
							{country === "co" ? "Alix es uno de los miles de tenderos que cuentan con Chiper para abastecer su tienda al mejor precio." : titleVideo}
						</p>
					</div>
					<div className={styles.helpedVideoCols}>
						<div className={styles.helpedVideoBox}>
							<iframe
								width="100%"
								height="400"
								src={country === "co" ? "https://www.youtube.com/embed/-o5zxsvjqS4" : "https://www.youtube.com/embed/XjJaztXfnRg"}
								title="YouTube video player"
								frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							/>
							<div className={styles.helpedVideoFooter}>
								<p>
									<strong>{country === "co" ? "Alix, Colombia" : "Alejandra, México"}</strong>
								</p>
								<p className={styles.helpedVideoClient}>
                Cliente desde 2019
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default StoresHelpedES;
