import { ViewportProps } from "../../../Stores/viewport.interface";
import styles from "../ChiperService.module.css";
import imgBox from "../../../../assets/images/imgBox.png";
import pointsBox from "../../../../assets/images/pointsBox.png";

function ChiperServiceCO({ forwardedRef }: ViewportProps) {
	return (
		<section id="chiperService" className={styles.sectionService} ref={forwardedRef} data-testid="chiperService">
			<div className={styles.containerChiperService}>
				<div className={styles.containerTitleService}>
					<div>
						<h1><b>Chiper te </b></h1>
						<h1><b>brinda un</b></h1>
						<h1><b>servicio</b></h1>
						<h1 className={styles.titleUnderlineStyle1}>
							<b className={styles.underline}>estable y</b>
						</h1>
						<h1 className={styles.titleUnderlineStyle2}>
							<b className={styles.underline}>garantizado</b>
						</h1>
					</div>
				</div>
				<div className={styles.containerImgService}>
					<div className={styles.containerTextBodyService}>
						<p className={styles.bodyTextService}>Al tener nuestros créditos,
                    Chiper te asignará los viajes mínimos
                        que te permitirán generar los mayores
                        ingresos en nuestros planes.
						</p>
					</div>
					<img src={imgBox} className={styles.imgBox} alt="imgBox" />
					<img src={pointsBox} className={styles.imgPointsBox} alt="imgPoints" />
				</div>
			</div>
		</section>
	);
}

export default ChiperServiceCO;
