import { useContext } from "react";
import { AppContext } from "../../../../contexts/AppContext";
import { ViewportProps } from "../../../Stores/viewport.interface";
import styles from "../ChiperOffers.module.css";
import ListItemsOffers from "../ListItemsOffers";

function ChiperOffersBR({ forwardedRef }: ViewportProps) {
	const { translate } = useContext(AppContext);
	return (
		<section className={styles.sectionChiperOffers} id="chiperOffers" ref={forwardedRef} data-testid="ChiperOffersBR">
			<div className={styles.containerChiperOffers}>
				<div className={styles.headerChiperOffers}>
					<h1>{translate("global.careers.benefits.title")}</h1>
				</div>
				<div className={styles.bodyChiperOffers}>
					<ListItemsOffers />
				</div>
			</div>
		</section>
	);
}

export default ChiperOffersBR;
