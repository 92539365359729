import { useContext } from "react";
import { ViewportProps } from "../../Stores/viewport.interface";
import styles from "./ChiperPrinciples.module.css";
import secondDots from "../../../assets/images/imagesCareers/secondDots.png";
import { AppContext } from "../../../contexts/AppContext";
import ListItemsPrinciples from "./ListItemsPrinciples";

function ChiperPrinciples({ forwardedRef }: ViewportProps) {
	const { translate } = useContext(AppContext);
	return (
		<section className={styles.sectionChiperPrinciples} id="chiperPrinciples" ref={forwardedRef}>
			<div className={styles.containerChiperPrinciples}>
				<div className={styles.headerChiperPrinciples}>
					<h1>{translate("global.careers.dns.title")}</h1>
					<img src={secondDots} alt="imgDots" />
				</div>
				<div className={styles.bodyChiperPrinciples}>
					<ListItemsPrinciples />
				</div>
			</div>
		</section>
	);
}

export default ChiperPrinciples;
