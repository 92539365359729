import { useContext } from "react";
import { AppContext } from "../../../../contexts/AppContext";
import styles from "../ChiperBenefits.module.css";
import imgPoints3 from "../../../../assets/images/imgPoints3.png";
import imgRocket from "../../../../assets/images/imgRocket.png";

function ChiperBenefitsCO() {
	const { changeRegisterScreen } = useContext(AppContext);
	const mobile = window.screen.width <= 480;

	const handleOnClick = () => {
		changeRegisterScreen(true);
	};

	return (
		<section id="chiperBenefits" className={styles.sectionBenefits} data-testid="chiperBenefits">
			<div className={styles.containerChiperBenefits}>
				<div className={styles.containerImgBenefits}>
					<img src={imgPoints3} alt="imgPoints3" className={styles.pointsImg} />
					<img src={imgRocket} alt="imgPoints3" className={styles.imgRocket} />
				</div>
				<div className={styles.bodyServiceContent}>
					<div className={styles.contentTitleBenefits}>
						<h1><b>Te premiamos</b></h1>
						<h1><b>por tu buen</b></h1>
						<h1><b>desempeño</b></h1>
						<p>Por tu buen desempeño podrás acceder a extensos
                   planes de beneficios, y así Chiper se convertirá
                    en el aliado #1 para aumentar tus ingresos.
						</p>
					</div>
					{mobile && (
						<div>
							<img src={imgRocket} alt="imgPoints3" className={styles.imgRocketMobile} />
						</div>
					)}
					<button type="button" className={styles.btnBenefitsRegister} onClick={handleOnClick}>Registrarme</button>
				</div>
			</div>
		</section>
	);
}

export default ChiperBenefitsCO;
