import { useContext } from "react";
import handleViewport from "react-in-viewport";
import { AppContext } from "../../../contexts/AppContext";
import ChiperOffersBR from "./ChiperOffersBR";
import ChiperOffersCO from "./ChiperOffersCO";
import ChiperOffersLA from "./ChiperOffersLA";
import ChiperOffersMX from "./ChiperOffersMX";

const ViewportChiperOffersBR = handleViewport(ChiperOffersBR);
const ViewportChiperOffersCO = handleViewport(ChiperOffersCO);
const ViewportChiperOffersMX = handleViewport(ChiperOffersMX);
const ViewportChiperOffersLA = handleViewport(ChiperOffersLA);

function ChiperOffersCareers() {
	const { country, countryCodes } = useContext(AppContext);

	if (country === countryCodes.BR) return <ViewportChiperOffersBR />;
	if (country === countryCodes.CO) return <ViewportChiperOffersCO />;
	if (country === countryCodes.MX) return <ViewportChiperOffersMX />;

	return <ViewportChiperOffersLA />;
}

export default ChiperOffersCareers;
