import MultiCarousel from "react-multi-carousel";
import { useContext } from "react";
import styles from "../Customers.module.css";
import firstEmployee from "../../../assets/images/imagesCareers/firstEmployee.png";
import secondEmployee from "../../../assets/images/imagesCareers/secondEmployee.png";
import thirdEmployee from "../../../assets/images/imagesCareers/thirdEmployee.png";
import fourthEmployee from "../../../assets/images/imagesCareers/fourthEmployee.png";
import "react-multi-carousel/lib/styles.css";
import { AppContext } from "../../../contexts/AppContext";

function CareersChiperPeople() {
	const { translate } = useContext(AppContext);
	const responsive = {
		desktop: {
			breakpoint: { max: 5000, min: 1024 },
			items: 3,
			slidesToSlide: 3,
			partialVisibilityGutter: 40,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 3,
			slidesToSlide: 1,
			partialVisibilityGutter: 30,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1,
			partialVisibilityGutter: 30,
		},
	};

	return (
		<MultiCarousel
			swipeable
			draggable
			showDots
			ssr
			responsive={responsive}
			removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
			dotListClass={styles.customersDots}
		>
			<div className={styles.customersItem}>
				<p className={styles.textBodyCareers}>
					{translate("global.careers.testimoinal.list.0.body")}
				</p>

				<div className={styles.customersItemUser}>
					<img className={styles.customersItemUserAvatar} src={firstEmployee} alt="" />
					<div>
						<p className={styles.customersItemUserName}><b>{translate("global.careers.testimoinal.list.0.author")}</b></p>
						<div className={styles.customersDataPeople}>
							<p className={styles.textCareers}>{translate("global.careers.testimoinal.list.0.since")}</p>
							<p className={styles.textCareers}>{translate("global.careers.testimoinal.list.0.country")}</p>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.customersItem}>
				<p className={styles.textBodyCareers}>
					{translate("global.careers.testimoinal.list.1.body")}
				</p>

				<div className={styles.customersItemUser}>
					<img className={styles.customersItemUserAvatar} src={secondEmployee} alt="" />
					<div>
						<p className={styles.customersItemUserName}><b>{translate("global.careers.testimoinal.list.1.author")}</b></p>
						<div className={styles.customersDataPeople}>
							<p className={styles.textCareers}>{translate("global.careers.testimoinal.list.1.since")}</p>
							<p className={styles.textCareers}>{translate("global.careers.testimoinal.list.1.country")}</p>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.customersItem}>
				<p className={styles.textBodyCareers}>
					{translate("global.careers.testimoinal.list.2.body")}
				</p>

				<div className={styles.customersItemUser}>
					<img className={styles.customersItemUserAvatar} src={thirdEmployee} alt="" />
					<div>
						<p className={styles.customersItemUserName}><b>{translate("global.careers.testimoinal.list.2.author")}</b></p>
						<div className={styles.customersDataPeople}>
							<p className={styles.textCareers}>{translate("global.careers.testimoinal.list.2.since")}</p>
							<p className={styles.textCareers}>{translate("global.careers.testimoinal.list.2.country")}</p>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.customersItem}>
				<p className={styles.textBodyCareers}>
					{translate("global.careers.testimoinal.list.3.body")}
				</p>

				<div className={styles.customersItemUser}>
					<img className={styles.customersItemUserAvatar} src={fourthEmployee} alt="" />
					<div>
						<p className={styles.customersItemUserName}><b>{translate("global.careers.testimoinal.list.3.author")}</b></p>
						<div className={styles.customersDataPeople}>
							<p className={styles.textCareers}>{translate("global.careers.testimoinal.list.3.since")}</p>
							<p className={styles.textCareers}>{translate("global.careers.testimoinal.list.3.country")}</p>
						</div>
					</div>
				</div>
			</div>
		</MultiCarousel>
	);
}

export default CareersChiperPeople;
