import { useContext } from "react";
import cn from "classnames";
import { AppContext } from "../../../contexts/AppContext";

import styles from "./FirstOrder.module.css";
import imagePT from "../../../assets/images/pt/pt-image-6.png";
import imageES from "../../../assets/images/es/es-image-6.png";
import { ViewportProps } from "../viewport.interface";

function StoresFirstOrder({ inViewport, forwardedRef }: ViewportProps) {
	const { country, translate } = useContext(AppContext);
	const msgTitle = country === "cl" ? "firstOrder.subtitle.cl" : "firstOrder.subtitle";

	return (
		<section id="first-order" className={styles.firstOrder} ref={forwardedRef}>
			<div className="container">
				<div className={styles.firstOrderTitleCols}>
					<div className="overflow-hidden">
						<div
							className={
								inViewport ? cn(styles.animationStart, styles.animationEnd) : styles.animationStart
							}
						>
							<h1 className={styles.firstOrderTitle}><b>{translate("firstOrder.title")}</b></h1>
						</div>
					</div>
					<p>{translate(msgTitle)}</p>
				</div>
				<div className={styles.firstOrderImgCols}>
					<div className={styles.firstOrderImg}>
						<img src={country === "br" ? imagePT : imageES} alt="" />
					</div>
				</div>
			</div>
		</section>
	);
}

export default StoresFirstOrder;
