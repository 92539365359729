import { useContext } from "react";
import handleViewport from "react-in-viewport";
import { AppContext } from "../../../contexts/AppContext";
import ChiperBenefitsCO from "./ChiperBenefitsCO";

const ViewportBenefitsCO = handleViewport(ChiperBenefitsCO);

function ChiperBenefits() {
	const { country, countryCodes, registerScreen } = useContext(AppContext);

	if (country === countryCodes.CO && !registerScreen) return <ViewportBenefitsCO />;

	return <> </>;
}

export default ChiperBenefits;
