// eslint-disable-next-line no-use-before-define

interface Props {
  message: string;
}

function ToastMessageStyle({ message }: Props) {
	return (
		<div className="relative">
			<div className="w-full flex items-start">
				<p
					className="text-base flex-1 text-white font-medium pl-2 leading-tight"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: message }}
				/>
			</div>
		</div>
	);
}

export default ToastMessageStyle;
