import TransformingFutureBanner from "./TransformingFutureBanner";
import ServicesChiper from "./ServicesChiper";
import ChiperInNumbers from "./ChiperInNumbers";
import ExperienceChiper from "./ExperienceChiper";
import MissionChiperBanner from "./MissionChiperBanner";
import BetterBusinessBanner from "./BetterBusinessBanner";

function About() {
	return (
		<div data-testid="AboutScreen">
			<TransformingFutureBanner />
			<ChiperInNumbers />
			<BetterBusinessBanner />
			<ServicesChiper />
			<ExperienceChiper />
			<MissionChiperBanner />
		</div>
	);
}

export default About;
