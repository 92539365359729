import { Link } from "react-router-dom";

interface LinkProps {
  to: string;
  name: string;
  className: string;
}

interface FooterLegalLinksProps {
  links: LinkProps[];
}

function FooterLegalLinks({ links }: FooterLegalLinksProps) {
	return (
		<>
			{links.map(({ to, className, name }) => (
				<Link key={to} to={to} className={className}>
					{name}
				</Link>
			))}
		</>
	);
}

export default FooterLegalLinks;
