import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import FooterBR from "./FooterBR";
import FooterCO from "./FooterCO";
import FooterLA from "./FooterLA";
import FooterMX from "./FooterMX";
import FooterCL from "./FooterCL";

function Footer() {
	const { country, countryCodes } = useContext(AppContext);
	const footerByCountry = () => {
		if (country === countryCodes.BR) {
			return <FooterBR />;
		}
		if (country === countryCodes.CO) {
			return <FooterCO />;
		}
		if (country === countryCodes.MX) {
			return <FooterMX />;
		}
		if (country === countryCodes.CL) {
			return <FooterCL />;
		}
		return <FooterLA />;
	};

	return (
		<div data-testid="Footer">
			{footerByCountry()}
		</div>
	);
}

export default Footer;
