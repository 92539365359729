/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useContext, useState } from "react";
import { FileError, FileRejection, useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import Axios from "axios";
import docBack from "../../../../assets/images/docBack.svg";
import docFront from "../../../../assets/images/docFront.svg";
import styles from "./uploadFiles.module.css";
import { dataFileDocument, FormContext } from "../../../../contexts/FormContext";
import ToastMessageStyle from "../../../../components/ToastAlert";
import ProgressBar from "../../../../components/ProgressBar";

interface UploadableFile{
    file: File;
    errors: FileError[];
}

interface UploadDocumentProps{
  sideTitle: string;
  onChange: (id: dataFileDocument) => void;
  uploadFile?: dataFileDocument;
  isPdf?: boolean;
  onDelete: () => void;
}

interface SingleFileUploadWithProgressProps{
  file: File[];
}

function UploadDocument({
	sideTitle, onChange, uploadFile, isPdf, onDelete,
}: UploadDocumentProps) {
	const { REACT_APP_LANDING_API } = process.env;
	const { fileIdUploadDoc, leadIdUploadDoc } = useContext(FormContext);
	const [files, setFiles] = useState<UploadableFile[]>([]);
	const [dataUploadFile, setDataUploadFile] = useState<boolean | any>();
	const [dataImage, setDataImage] = useState<any>();

	function SingleFileUploadWithProgress({ file }: SingleFileUploadWithProgressProps) {
		const formData = new FormData();
		formData.append("file", file[0]);
		formData.append("fileTypeId", fileIdUploadDoc);
		formData.append("leadId", leadIdUploadDoc);
		formData.append("side", sideTitle);
		setDataImage(file[0]);
		Axios({
			method: "POST",
			data: formData,
			url: `${REACT_APP_LANDING_API}lead-file`,
			headers: {
				"Content-Type": "multipart/form-data",
			},
			onUploadProgress(e: any) {
				if (e.lengthComputable) {
					const percentage = Math.round((e.loaded / e.total) * 100);
					onChange({ progress: percentage });
				}
			},
		})
			.then((resp: any) => {
				setDataUploadFile(resp.data);
				onChange({
					name: file[0].name,
					size: file[0].size,
					id: resp.data.id as string,
					preview: URL.createObjectURL(file[0]),
					progress: 0,
				});
			})
			.catch((e: any) => {
				toast.error(<ToastMessageStyle message={e?.response?.data?.message} />, {
					position: toast.POSITION.TOP_CENTER,
				});
			});
	}

	const onDrop = useCallback((accFiles: File[], rejFiles: FileRejection[]) => {
		const mapAcc = accFiles.map((file) => ({ file, errors: [] }));
		setFiles((curr) => [...curr, ...mapAcc, ...rejFiles]);
		SingleFileUploadWithProgress({ file: accFiles });
	}, [fileIdUploadDoc, dataUploadFile]);

	const { getRootProps, getInputProps } = useDropzone({ onDrop, maxSize: 5e+6, maxFiles: 1 });

	return (
		<div className="w-full px-2">
			{!(isPdf) ? (
				<div>
					{ !uploadFile ? (
						<div className={styles.divUpDoc}>
							<div {... getRootProps()}>
								<div className={styles.containerUpDoc}>
									<input {... getInputProps()} />
									<img src={(sideTitle === "Front") ? docFront : docBack} alt="imgFiles" className="w-14 h-14 mt-3" />
									<p className={styles.sideTitleUpDoc}>{sideTitle === "Front" ? "Parte delantera" : "Parte trasera"}</p>
									<p className={styles.firstTitleUpDoc}>
                    Arrastre y suelte o <u className="text-blue-600">busque</u> sus archivos
									</p>
									<p className={styles.grayTextUpDoc}>Tamaño máximo de archivo: 5mb</p>
								</div>
							</div>
						</div>
					) : (
						<ProgressBar
							sideTitle={sideTitle}
							onDelete={onDelete}
							file={uploadFile}
						/>
					)}
				</div>
			) : (
				<div>
					{ !uploadFile ? (
						<div className={styles.divUpPDF} {... getRootProps()}>
							<div className={styles.containerUpDoc}>
								<input {... getInputProps()} />
								<img src={docFront} alt="imgFiles" className="w-14 h-14 mt-3 mb-7" />
								<p className={styles.firstTitleUpDoc}>
              Arrastre y suelte o <u className="text-blue-600">busque</u> sus archivos
								</p>
								<p className={styles.grayTextUpDoc}>Tamaño máximo de archivo: 5mb</p>
							</div>
						</div>
					) : (
						<ProgressBar
							sideTitle={sideTitle}
							onDelete={onDelete}
							file={uploadFile}
						/>
					)}
				</div>
			)}
		</div>
	);
}

export default UploadDocument;
