/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext } from "react";
import { AppContext } from "../../../../contexts/AppContext";
import { ViewportProps } from "../../../Stores/viewport.interface";
import styles from "../RegistrationSteps.module.css";
import pointsBox from "../../../../assets/images/pointsBox.png";

function RegistrationStepsCO({ forwardedRef }: ViewportProps) {
	const { changeRegisterScreen } = useContext(AppContext);
	const mobile = window.screen.width <= 480;

	const handleOnClick = () => {
		changeRegisterScreen(true);
	};

	return (
		<section id="registrationSteps" className={styles.sectionSteps} ref={forwardedRef}>
			<div className={styles.containerRegistrationSteps}>
				<div className={styles.titleSteps}>
					<div className={styles.containerTitleRegister}>
						<h1>
							<b>Registrarse es muy fácil</b>
						</h1>
						{mobile && (
							<p>
              Al tener nuestros créditos, Chiper te asignará los viajes mínimos que te permitirán
              generar mayores ingresos en nuestros planes.
							</p>
						)}
					</div>
					<img src={pointsBox} alt="points" className={styles.pointsTitle} />
				</div>
				<div className={styles.containerSteps}>
					<div className={styles.steps1}>
						<li>1</li>
						<p>Completa tu solicitud</p>
					</div>
					<div className={styles.steps}>
						<li>2</li>
						<p>
              Nos pondremos en contacto contigo para verificar la información y programar una cita
						</p>
					</div>
					<div className={styles.steps}>
						<li>3</li>
						<p>Entrevista para conocerte mejor. ¡Aprovecha para salir de tus dudas!</p>
					</div>
					<div className={styles.steps}>
						<li>4</li>
						<p>Si eres aprobado, te enviaremos los términos del servicio y crédito</p>
					</div>
					<div className={styles.steps}>
						<li>5</li>
						<p>Si es aprobada la propuesta, ¡recibe tu vehículo y comienza a repartir!</p>
					</div>
					<button type="button" className={styles.btnStepsRegister} onClick={handleOnClick}>
            Registrarme
					</button>
				</div>
			</div>
		</section>
	);
}

export default RegistrationStepsCO;
