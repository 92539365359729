/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import cn from "classnames";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import HeaderLinks from "./Header.links";

import ChiperLogo from "../../assets/images/chiper-logo.svg";
import xClose from "../../assets/images/xClose.svg";
import Menu from "../../assets/images/Menu.png";
import styles from "./Header.module.css";
import { RoutesNames } from "../../constants";

function Header() {
	const { pathname } = useLocation();
	const {
		country, translate, registerScreen, changeRegisterScreen,
	} = useContext(AppContext);
	const [scrollPosition, setScrollPosition] = useState(0);
	const [headerCollapsed, setHeaderCollapsed] = useState(false);
	const [oldURL, setOldURL] = useState<any>();
	const scrollYMax = 500;
	const mobile = window.screen.width <= 480;

	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);
	};

	const toggleHeader = () => {
		setHeaderCollapsed(!headerCollapsed);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll, false);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	useEffect(() => {
		setOldURL(window.location.href);
		if (window.location.href !== oldURL) {
			setHeaderCollapsed(false);
		}
	});

	return (
		<header
			id="headerTop"
			className={
				scrollPosition < scrollYMax ? styles.header : cn(styles.header, styles.headerStick)
			}
		>
			<div className={styles.container}>
				<Link to={`/${country}/${RoutesNames.stores}`} onClick={() => changeRegisterScreen(false)}>
					<img className={styles.logo} src={ChiperLogo} width={100} alt="Chiper" />
				</Link>
				{ !registerScreen && (<img className={styles.buttonMenu} src={Menu} alt="menu" onClick={() => toggleHeader()} />)}
				{!mobile && (
					<div className={styles.headerLinksContainer}>
						<div
							className={
								scrollPosition > scrollYMax
									? cn(styles.animateMenuInitial, styles.animateMenuStart)
									: styles.animateMenuInitial
							}
						>
							{!registerScreen && <HeaderLinks />}
						</div>
						{!registerScreen && (
							<div
								className={
									scrollPosition > scrollYMax
										? cn(styles.downloadBtn, styles.animateDownloadBtn)
										: styles.downloadBtn
								}
								style={pathname !== `/${country}/${RoutesNames.careers}` && pathname !== `/${country}/${RoutesNames.stores}` ? { display: "none" } : {}}
							>
								{ pathname === `/${country}/${RoutesNames.stores}` && (
									<button type="button" onClick={() => window.scrollTo(0, 7250)}>
										{country === "br" ? "Baixar app" : "Download app"}
									</button>
								) }
								{ pathname === `/${country}/${RoutesNames.careers}` && (
									<button
										type="button"
										onClick={() => {
											window.open("https://chiper.bamboohr.com/jobs/");
										}}
									>
										{translate("global.careers.button.applyNow")}
									</button>
								)}
							</div>
						)}
					</div>
				)}
				{mobile && headerCollapsed && (
					<div className="fixed top-0 left-0 w-screen h-screen z-0 bg-black bg-opacity-20">
						<div className={styles.headerLinksContainer}>
							<div className={styles.menuMobile}>
								<HeaderLinks />
								<div className={pathname === `/${country}/${RoutesNames.careers}` ? styles.buttonGreen : "hidden"}>
									{pathname === `/${country}/${RoutesNames.careers}` && (
										<button
											type="button"
											onClick={() => {
												window.open("https://chiper.bamboohr.com/jobs/");
											}}
										>
											{translate("global.careers.button.applyNow")}
										</button>
									)}
								</div>
							</div>
							<div className={styles.buttonCloseMobile}>
								<button type="button" className="font-black" onClick={() => toggleHeader()}>
									<img src={xClose} alt="close" />
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</header>
	);
}

export default Header;
