import { useContext } from "react";
import CardItemOffers from "./CardItemsOffers";
import Team from "../../../assets/images/imagesCareers/Team.svg";
import Career from "../../../assets/images/imagesCareers/Career.svg";
import Remote from "../../../assets/images/imagesCareers/Remote.svg";
import Devices from "../../../assets/images/imagesCareers/Devices.svg";
import Birthday from "../../../assets/images/imagesCareers/Birthday.svg";
import DistanceEducation from "../../../assets/images/imagesCareers/DistanceEducation.svg";
import MedicalCare from "../../../assets/images/imagesCareers/MedicalCare.svg";
import MedicalInsurance from "../../../assets/images/imagesCareers/MedicalInsurance.svg";
import styles from "./ChiperOffers.module.css";
import { AppContext } from "../../../contexts/AppContext";

function ListItemsOffers() {
	const { translate } = useContext(AppContext);
	return (
		<div className={styles.listItemsOffers}>
			<CardItemOffers iconItem={Team} text={translate("global.careers.benefits.list.0.body")} />
			<CardItemOffers iconItem={Career} text={translate("global.careers.benefits.list.1.body")} />
			<CardItemOffers iconItem={Remote} text={translate("global.careers.benefits.list.2.body")} />
			<CardItemOffers iconItem={Devices} text={translate("global.careers.benefits.list.3.body")} />
			<CardItemOffers iconItem={Birthday} text={translate("global.careers.benefits.list.4.body")} />
			<CardItemOffers iconItem={DistanceEducation} text={translate("global.careers.benefits.list.5.body")} />
			<CardItemOffers iconItem={MedicalCare} text={translate("global.careers.benefits.list.6.body")} />
			<CardItemOffers iconItem={MedicalInsurance} text={translate("global.careers.benefits.list.7.body")} />
		</div>
	);
}

export default ListItemsOffers;
