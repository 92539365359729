import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import TermsBR from "./TermsBR";
import TermsCO from "./TermsCO";
import TermsMX from "./TermsMX";
import TermsCL from "./TermsCL";

function Terms() {
	const { doc } = useParams();
	const { country, countryCodes } = useContext(AppContext);
	const detectUrl = window.location.pathname;

	const termsByCountry = () => {
		if (detectUrl === "/br/terms/conditions") return <TermsBR doc={doc} />;
		if (country === countryCodes.BR) return <TermsBR doc={doc} />;
		if (country === countryCodes.CO) return <TermsCO doc={doc} />;
		if (country === countryCodes.MX) return <TermsMX doc={doc} />;
		if (country === countryCodes.CL) return <TermsCL doc={doc} />;
		return <> </>;
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [doc]);

	return (
		<div data-testid="TermsScreen">
			{termsByCountry()}
		</div>
	);
}

export default Terms;
