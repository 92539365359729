import { useState } from "react";
import { ViewportProps } from "../../../Stores/viewport.interface";
import styles from "../FAQDrivers.module.css";
import arrowFAQ from "../../../../assets/images/arrowFAQ.svg";

function FAQDriversCO({ forwardedRef }: ViewportProps) {
	const [question1, setQuestion1] = useState<boolean>(false);
	const [question3, setQuestion3] = useState<boolean>();

	return (
		<section id="faqDrivers" className={styles.sectionFAQ} ref={forwardedRef}>
			<div className={styles.containerFAQ}>
				<h1>
					<b>Preguntas Frecuentes</b>
				</h1>
				<div className={styles.listFAQ}>
					<div className={styles.questionsList}>
						<button
							type="button"
							className={styles.questionsItems}
							onClick={() => setQuestion1(!question1)}
						>
							<li>
								<img src={arrowFAQ} alt="arrow" className={question1 ? "rotate-90" : ""} />
							</li>
							<p>¿Cómo Chiper me garantiza las rutas de transporte?</p>
						</button>
						{question1 && (
							<div className={styles.answerContainer}>
								<p>
                  Al tener un crédito de motocarro con Chiper, nos interesa que trabajes con
                  nosotros todos los días. Por eso se te asignarán envíos diarios que te permitan
                  ganar el ingreso máximo en nuestros planes. Los conductores que transporten todos
                  los días con Chiper, siempre tendrán la garantía de sus envíos.
								</p>
							</div>
						)}
						{/* Deshabilitado hasta que se diseñe el contenido
                <button type="button" className={styles.questionsItemsCenter}>
                    <li><img src={arrowFAQ} alt="arrow" /></li>
                    <p>¿Cómo funciona el subsidio de crédito de Chiper?</p>
                </button> */}
						<button
							type="button"
							className={styles.questionsItems3}
							onClick={() => setQuestion3(!question3)}
						>
							<li>
								<img src={arrowFAQ} alt="arrow" className={question3 ? "rotate-90" : ""} />
							</li>
							<p>¿Cuánto me cuesta el crédito y cómo lo pago?</p>
						</button>
						{question3 && (
							<div className={styles.answerContainer}>
								<p>
                  En Chiper nos preocupamos por hacerte la vida fácil y que generes las mayores
                  ganancias. Puedes obtener créditos con cuotas accesibles y lo puedes pagar
                  fácilmente descontándolos de tus ganancias diarias por hacer envíos de Chiper.
                  Adicionalmente, tenemos nuestra red de Corresponsales no Bancarios en caso de que
                  debas pagar la cuota directamente.
								</p>
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	);
}

export default FAQDriversCO;
