import useMediaQuery from "../../../hooks/useMediaQuery";
import manWithPhone from "../../../assets/images/imagesAboutUs/manWithPhone.png";
import firstDots from "../../../assets/images/imagesCareers/firstDots.png";

function ServicesChiper() {
	const isDesktop = useMediaQuery("(min-width: 600px)");

	return (
		<div className={isDesktop ? "pt-32 pl-32" : "px-5 py-20"}>
			<div className={isDesktop ? "flex justify-center" : "flex-col"}>
				<p
					className={`font-bold
          ${
		isDesktop ? "text-7xl max-w-4xl mx-10 text-right" : "text-5xl text-right items-center"
		}`}
				>
          While there are 3.7 million corner stores with a TAM of $500B, less than 1% of B2B
          purchases are done online
				</p>
				{isDesktop && (
					<div className="flex">
						<img
							className="block"
							src={firstDots}
							style={{ maxWidth: 600, margin: "auto" }}
							height={19}
							alt=""
						/>
					</div>
				)}
			</div>
			<div className={isDesktop ? "flex items-center justify-center" : "flex- items-center justify-center"}>
				<div className="text-center">
					<img src={manWithPhone} alt="" />{" "}
				</div>
				<p
					style={{ textShadow: isDesktop ? "#cbcbcb 0px 4px 3px" : "none" }}
					className={isDesktop ? "text-5xl ml-10 " : "text-5xl"}
				>
          Leaving corner store owners stuck
					<br /> replenishing their businesses the same <br /> way they have since the 20th century
				</p>
			</div>
		</div>
	);
}

export default ServicesChiper;
