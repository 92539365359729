import { useContext } from "react";
import handleViewport from "react-in-viewport";
import { AppContext } from "../../../contexts/AppContext";
import RegistrationStepsCO from "./RegistrationStepsCO";

const ViewportRegisterStepsCO = handleViewport(RegistrationStepsCO);

function RegisterSteps() {
	const { country, countryCodes, registerScreen } = useContext(AppContext);

	if (country === countryCodes.CO && !registerScreen) return <ViewportRegisterStepsCO />;

	return <> </>;
}

export default RegisterSteps;
