import { useContext } from "react";
import { AppContext } from "../../../contexts/AppContext";
import { ViewportProps } from "../../Stores/viewport.interface";
import styles from "./OurBusiness.module.css";
import firstDots from "../../../assets/images/imagesCareers/firstDots.png";

function OurBusinessCarrers({ forwardedRef }: ViewportProps) {
	const { translate, country } = useContext(AppContext);
	const msgTitleBussines = country === "cl" ? "global.careers.mission.title.2.cl" : "global.careers.mission.title.2";
	const msgTitleFirtGroup = country === "cl" ? "global.careers.mission.title.list.0.cl" : "global.careers.mission.title.list.0";
	const msgTitleFirtGroup1 = country === "cl" ? "global.careers.mission.title.list.1.cl" : "global.careers.mission.title.list.1";
	return (
		<section id="ourBusiness" className={styles.ourBusiness} ref={forwardedRef}>
			<div className={styles.containerOurBusiness}>
				<div className={styles.firstGroupBusiness}>
					<p className={styles.firstTitleBusiness}>
						{translate("global.careers.mission.title.1")}
					</p>
					<h1 className={styles.secondTitleBusiness}>
						{translate(msgTitleBussines)}
					</h1>
					<p className={styles.textFirstGroup}>
						{translate(msgTitleFirtGroup)}
					</p>
				</div>
				<div className={styles.secondGroupBusiness}>
					<img src={firstDots} alt="imgDots" />
					<p className={styles.textSecGroup}>
						{translate(msgTitleFirtGroup1)}
					</p>
				</div>
				<div className={styles.thirdGroupBusiness}>
					<p className={styles.textThirdGroup}>
						{translate("global.careers.mission.title.list.2")}
					</p>
				</div>
			</div>
		</section>
	);
}

export default OurBusinessCarrers;
