/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from "chiper-sb-ecommerce";
import { useContext } from "react";
import styles from "./successfulRegistration.module.css";
import deliveryExitRegister from "../../../../assets/images/deliveryExitRegister.svg";
import { AppContext } from "../../../../contexts/AppContext";
import { FormContext } from "../../../../contexts/FormContext";

function SuccessfulRegistrationCO() {
	const { changeRegisterScreen } = useContext(AppContext);
	const {
		changeNameRegisterDriverForm,
		changeLastNameRegisterDriverForm,
		changeCellphoneRegisterDriverForm,
		changeCityRegisterDriverForm,
		changeDriverWithVehicle,
		changeDriverNoVehicle,
		changeAcceptTyC,
	} = useContext(FormContext);

	const handleOnClick = () => {
		changeRegisterScreen(false);
		changeNameRegisterDriverForm("");
		changeLastNameRegisterDriverForm("");
		changeCellphoneRegisterDriverForm("");
		changeCityRegisterDriverForm("");
		changeDriverWithVehicle(false);
		changeDriverNoVehicle(false);
		changeAcceptTyC(false);
	};

	return (
		<div className={styles.containerFinishRegistration}>
			<div className={styles.bodyFinishRegistration}>
				<h1>
					<b>¡Tu registro fue exitoso!</b>
				</h1>
				<p>Te enviaremos un mensaje al terminar de revisar tu solicitud.</p>
				<Button onClick={handleOnClick} className={styles.btnGreen}>Volver al sitio</Button>
			</div>
			<img src={deliveryExitRegister} alt="imgDelivery" />
		</div>
	);
}

export default SuccessfulRegistrationCO;
