import { useContext, useEffect, useState } from "react";
import cn from "classnames";
import { ViewportProps } from "../viewport.interface";
import QRCode from "../../../components/QRCode";
import { AppContext } from "../../../contexts/AppContext";
import styles from "./Banner.module.css";

function StoresBanner({ inViewport, forwardedRef }: ViewportProps) {
	const { country, countryCodes, translate } = useContext(AppContext);
	const [background, setBackground] = useState("");
	const [bannerTitle, setBannerTitle] = useState("");
	const showBrazil = country === countryCodes.BR;

	useEffect(() => {
		if (showBrazil) {
			setBackground(cn(styles.bannerBackground, styles.bannerPTBackground));
		}

		if (country !== countryCodes.BR) {
			setBackground(cn(styles.bannerBackground, styles.bannerESBackground));
			setBannerTitle(styles.bannerTitleES);
		}
	}, [country]);

	const titleBanner = country === countryCodes.CL ? "banner.title.CL" : "banner.title.CO";

	return (
		<section id="banner" className={styles.banner} ref={forwardedRef}>
			<div className={background}>
				<div className="container">
					<div className={styles.bannerTitleCols}>
						<div className="overflow-hidden">
							<div
								className={
									inViewport
										? cn(styles.animationStart, styles.animationEnd)
										: styles.animationStart
								}
							>
								<h1>
									{ showBrazil ? (
										<b className={styles.underline}>
											{translate("banner.title.underline")}
										</b>
									) : (
										<>
											<b className={styles.underline}>Gana más</b>
											<b className="underlineChiper">dinero</b>
										</>
									)}
								</h1>
								<h1 className={bannerTitle}>
									<b>{ country === countryCodes.CO ? translate("banner.title.MX") : translate(titleBanner)}</b>
								</h1>
							</div>
						</div>
						<div className={styles.bannerQRCodeCols}>
							<div className={styles.bannerQRCodeImage}>
								<QRCode />
							</div>
							<div className={styles.bannerQRCodeText}>
								<p className={styles.bannerQRCodeTextTitle}>
									<strong>{translate("banner.qrcode.title")}</strong>
								</p>
								<p className={styles.bannerQRCodeTextSubtitle}>{translate("banner.qrcode.text")}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default StoresBanner;
