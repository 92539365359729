import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { RoutesNames } from "../../constants";
import { AppContext } from "../../contexts/AppContext";

interface AppIndexProps {
  country?: string;
}

function AppIndex({ country }:AppIndexProps) {
	const { countryCodes } = useContext(AppContext);
	if (country === countryCodes.BR) {
		return <Navigate to={`/${countryCodes.BR}/${RoutesNames.stores}`} replace />;
	}
	if (country === countryCodes.CO) {
		return <Navigate to={`/${countryCodes.CO}/${RoutesNames.stores}`} replace />;
	}
	if (country === countryCodes.MX) {
		return <Navigate to={`/${countryCodes.MX}/${RoutesNames.stores}`} replace />;
	}
	if (country === countryCodes.CL) {
		return <Navigate to={`/${countryCodes.CL}/${RoutesNames.stores}`} replace />;
	}
	return <Navigate to={`/${countryCodes.LA}/${RoutesNames.about}`} replace />;
}

export default AppIndex;
