function FooterLA() {
	return (
		<div
			style={{
				height: 200,
				display: "flex",
				justifyContent: "center",
			}}
			className="bg-chiper-gray"
		>

			<p className="my-auto text-xs">© 2022 - Chiper S.A.S.</p>
		</div>
	);
}

export default FooterLA;
