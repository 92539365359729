import { useContext, useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { AppContext } from "../../contexts/AppContext";

import "./ViewPDF.css";

interface ViewPDFProps {
  pdf: string;
}

const options = {
	cMapUrl: "cmaps/",
	cMapPacked: true,
};

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ViewPDF({ pdf }: ViewPDFProps) {
	const { translate, changeLanguage, language } = useContext(AppContext);
	const [totalPages, setTotalPages] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);

	function onDocumentLoadSuccess({ numPages }: any) {
		setTotalPages(numPages);
		setPageNumber(1);
	}

	function changePage(offset: number) {
		setPageNumber((prevPageNumber) => prevPageNumber + offset);
	}

	function previousPage() {
		changePage(-1);
	}

	function nextPage() {
		changePage(1);
	}

	useEffect(() => {
		setPageNumber(1);

		return () => {
			setPageNumber(0);
			changeLanguage(language);
		};
	}, [pdf]);

	return (
		<div className="view-pdf--document">
			<Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} options={options}>
				<Page
					pageNumber={pageNumber}
					scale={2}
					renderAnnotationLayer={false}
					renderTextLayer={false}
				/>
				<div className="footer">
					<p className="pages">
						{pageNumber || (totalPages ? 1 : "--")}/{totalPages || "--"}
					</p>
					<button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
						{translate("button.previous")}
					</button>
					<button type="button" disabled={pageNumber >= totalPages} onClick={nextPage}>
						{translate("button.next")}
					</button>
				</div>
			</Document>
		</div>
	);
}
