import Footer from "./components/Footer";
import Header from "./components/Header";
import HeadTags from "./components/HeadTags";
import AppRoutes from "./components/Routes";

function App() {
	return (
		<>
			<HeadTags />
			<Header />
			<AppRoutes />
			<Footer />
		</>
	);
}

export default App;
