import { useContext } from "react";
import SEO from "../../components/SEO";
import { AppContext } from "../../contexts/AppContext";
import DriversBanner from "./BannerDriver";
import ChiperBenefits from "./ChiperBenefits";
import ChiperServices from "./ChiperService";
import FAQDrivers from "./FAQDrivers";
import DriversRegister from "./RegisterDriver";
import RegisterSteps from "./RegistrationSteps";
import VehicleCredits from "./VehicleCredit";

function Drivers() {
	const { translate } = useContext(AppContext);
	return (
		<div data-testid="DriversScreen">
			<SEO
				title={translate("seo.stores.title")}
				description={translate("seo.stores.description")}
			/>
			<DriversBanner />
			<DriversRegister />
			<VehicleCredits />
			<ChiperServices />
			<ChiperBenefits />
			<RegisterSteps />
			<FAQDrivers />
		</div>
	);
}

export default Drivers;
