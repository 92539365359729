import MultiCarousel from "react-multi-carousel";
import { useContext } from "react";
import styles from "./Customers.module.css";

import Customer1 from "../../assets/images/customer-1.png";
import Customer2 from "../../assets/images/customer-2.png";
import Customer3 from "../../assets/images/customer-3.png";
import Customer4 from "../../assets/images/customer-4.png";

import "react-multi-carousel/lib/styles.css";
import { AppContext } from "../../contexts/AppContext";

function CarouselCustomers() {
	const { translate, country } = useContext(AppContext);
	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3,
			slidesToSlide: 1,
			partialVisibilityGutter: 30,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 3,
			slidesToSlide: 1,
			partialVisibilityGutter: 30,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1,
			partialVisibilityGutter: 30,
		},
	};

	return (
		<MultiCarousel
			swipeable
			draggable
			showDots
			partialVisible
			responsive={responsive}
			customTransition="all .5"
			transitionDuration={500}
			removeArrowOnDeviceType="desktop"
			containerClass={styles.customers}
			dotListClass={styles.customersDots}
			itemClass={styles.customersItem}
		>
			<div className={styles.customersItemFlex} data-testid="CustomersCO">
				<p className={styles.customersItemTitle}>
					{translate("customer.testimoinal.1")}
					<strong>{translate("customer.testimoinal.black.1")}</strong>
				</p>

				<div className={styles.customersItemUser}>
					<img className={styles.customersItemUserAvatar} src={Customer1} alt="" />
					<div>
						<p className={styles.customersItemUserName}>Alix</p>
						<div className={styles.customersItemUserStars}>
							<span className={styles.startActive}>&#9733;</span>
							<span className={styles.startActive}>&#9733;</span>
							<span className={styles.startActive}>&#9733;</span>
							<span className={styles.startActive}>&#9733;</span>
							<span>&#9733;</span>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.customersItemFlex}>
				<p className={styles.customersItemTitle}>
					{translate("customer.testimoinal.2")}
					<strong>{translate("customer.testimoinal.black.2")}</strong>
				</p>

				<div className={styles.customersItemUser}>
					<img className={styles.customersItemUserAvatar} src={Customer2} alt="" />
					<div>
						<p className={styles.customersItemUserName}>Cristian</p>
						<div className={styles.customersItemUserStars}>
							<span className={styles.startActive}>&#9733;</span>
							<span className={styles.startActive}>&#9733;</span>
							<span className={styles.startActive}>&#9733;</span>
							<span className={styles.startActive}>&#9733;</span>
							<span className={styles.startActive}>&#9733;</span>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.customersItemFlex}>
				<p className={styles.customersItemTitle}>
					{translate("customer.testimoinal.3")}
					<strong>{translate("customer.testimoinal.black.3")}</strong>
					{country !== "br" && "La aplicación es muy buena"}
				</p>

				<div className={styles.customersItemUser}>
					<img className={styles.customersItemUserAvatar} src={Customer3} alt="" />
					<div>
						<p className={styles.customersItemUserName}>Juliana</p>
						<div className={styles.customersItemUserStars}>
							<span className={styles.startActive}>&#9733;</span>
							<span className={styles.startActive}>&#9733;</span>
							<span className={styles.startActive}>&#9733;</span>
							<span className={styles.startActive}>&#9733;</span>
							<span>&#9733;</span>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.customersItemFlex}>
				<p className={styles.customersItemTitle}>
					{translate("customer.testimoinal.4")}
					<strong>{translate("customer.testimoinal.black.4")}</strong>
				</p>

				<div className={styles.customersItemUser}>
					<img className={styles.customersItemUserAvatar} src={Customer4} alt="" />
					<div>
						<p className={styles.customersItemUserName}>Carla</p>
						<div className={styles.customersItemUserStars}>
							<span className={styles.startActive}>&#9733;</span>
							<span className={styles.startActive}>&#9733;</span>
							<span className={styles.startActive}>&#9733;</span>
							<span className={styles.startActive}>&#9733;</span>
							<span>&#9733;</span>
						</div>
					</div>
				</div>
			</div>
		</MultiCarousel>
	);
}

export default CarouselCustomers;
